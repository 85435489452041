import { ref } from 'vue';
import {
  fetchCreateLink, fetchUploadToLink, fetchGetFilesList,
  fetchGetFileById, upload, fetchDeleteFileById,
} from '@/store';
import { generalErrorHandling } from '@/helpers/generalErrorHandling';
import { ICreateLinkBody, ICreateLinkResponse, IFile } from '@/types/api/filer';

const isGetLinkLoading = ref(false);
const isUploadToLinkLoading = ref(false);
const isUploadFileLoading = ref(false);
const isGetFileListLoading = ref(false);
const isGetFileByIdLoading = ref(false);
const isDeleteFileByIdLoading = ref(false);

const isGetLinkError = ref(false);
const isUploadToLinkError = ref(false);
const isUploadFileError = ref(false);
const isGetFileListError = ref(false);
const isGetFileByIdError = ref(false);
const isDeleteFileByIdError = ref(false);

const getLinkData = ref<ICreateLinkResponse>();
const uploadToLinkData = ref();
const getFileListData = ref<IFile[]>();
const getFileByIdData = ref<ICreateLinkResponse>();
const deleteFileByIdData = ref();

export const useFiler = () => {
  const getLink = async (linkData: ICreateLinkBody) => {
    isGetLinkLoading.value = true;
    isGetLinkError.value = false;
    const response = await fetchCreateLink(linkData).catch((error: Response) => {
      isGetLinkError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      getLinkData.value = response;
    }
    isGetLinkLoading.value = false;
  };

  const uploadToLink = async (url: string, file: File, type: string, task_id: string, file_id: string) => {
    isUploadToLinkLoading.value = true;
    isUploadToLinkError.value = false;
    // eslint-disable-next-line
    const response = await fetchUploadToLink(url, file, type, task_id, file_id).catch((error: Response) => {
      isUploadToLinkError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      // eslint-disable-next-line
      uploadToLinkData.value = response;
    }
    isUploadToLinkLoading.value = false;
  };

  const uploadFile = async (task_id: number, file: File) => {
    isUploadFileLoading.value = true;
    isUploadFileError.value = false;
    const linkData = {
      name: file.name,
      mime: file.type,
      task_id,
    };
    await getLink(linkData);
    if (!isGetLinkError.value && getLinkData.value?.url) {
      // await uploadToLink(getLinkData.value?.url, file, file.type, `${task_id}`, `${getLinkData.value?.meta.id}`);
      await upload(file, file.type, getLinkData.value?.url, `${task_id}`, `${getLinkData.value?.meta.id}`);
    }
    if (isGetLinkError.value || isUploadToLinkError.value) isUploadFileError.value = true;
    isUploadFileLoading.value = false;
  };

  const getFileList = async (task_id: number) => {
    isGetFileListLoading.value = true;
    isGetFileListError.value = false;
    const response = await fetchGetFilesList(task_id).catch((error: Response) => {
      isGetFileListError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      getFileListData.value = response.files;
    }
    isGetFileListLoading.value = false;
  };


  const getFileById = async (file_id: number) => {
    isGetFileByIdLoading.value = true;
    isGetFileByIdError.value = false;
    const response = await fetchGetFileById(file_id).catch((error: Response) => {
      isGetFileByIdError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      getFileByIdData.value = response;
    }
    isGetFileByIdLoading.value = false;
  };

  const deleteFileById = async (file_id: number) => {
    isDeleteFileByIdLoading.value = true;
    isDeleteFileByIdError.value = false;
    // eslint-disable-next-line
    const response = await fetchDeleteFileById(file_id).catch((error: Response) => {
      isDeleteFileByIdError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      // eslint-disable-next-line
      deleteFileByIdData.value = response;
    }
    isDeleteFileByIdLoading.value = false;
  };

  return {
    uploadFile,
    getFileList,
    getFileById,
    deleteFileById,

    isUploadFileLoading,
    isGetFileListLoading,
    isGetFileByIdLoading,
    isDeleteFileByIdLoading,

    isUploadFileError,
    isGetFileListError,
    isGetFileByIdError,
    isDeleteFileByIdError,

    uploadToLinkData,
    getFileListData,
    getFileByIdData,
    deleteFileByIdData,
  };
};
