<template>
  <aside class="NotificationSidebar notification-sidebar">
    <div class="notification-sidebar__header">
      <h4>Notifications</h4>
      <div
        class="notification-sidebar__close-icon"
        @click="notificationSidebarClose"
      >
        <img
          v-svg-inline
          :src="require('@/assets/images/icons/close.svg')"
          alt="notification sidebar close"
        >
      </div>
    </div>
    <div class="notification-sidebar__content">
      <div class="notification-sidebar__items">
        <div
          v-for="item in notifications"
          :key="item.created_at"
          class="notification-sidebar__item"
        >
          <p v-html="item.content" />
          <div class="notification-sidebar__item-info">
            <span class="notification-sidebar__item-date">
              {{ formatToDate(new Date(item.created_at).toISOString(), true) }}
            </span>
            <span class="notification-sidebar__item-type">
              {{ item.type }}
            </span>
          </div>
        </div>
      </div>
      <div class="notification-sidebar__btn">
        <BaseButton
          text="View All"
          cancel
          :to="{ name: routeNotifications }"
          :uppercase="false"
          @click="notificationSidebarClose"
        />
      </div>
    </div>
  </aside>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useCore } from '@/store';
import { useNotifications } from '@/composable';
import { ROUTE_HOME } from '@/helpers/enums/routes';
import { formatToDate } from '@/helpers/formatters/formatToDate';

import BaseButton from '@/components/ui/BaseButton.vue';

export default defineComponent({
  name: 'NotificationSidebar',
  components: {
    BaseButton,
  },
  setup() {
    const { person } = useCore();
    const { getNotificationsData: notifications, notificationSidebarClose } = useNotifications();

    const userId = computed(() => person.value.User?.id);

    return {
      notificationSidebarClose,
      formatToDate,
      notifications,
      routeNotifications: ROUTE_HOME,
      userId,
    };
  },
});
</script>

<style lang="scss" scoped>
.notification-sidebar {
  position: fixed;
  right: 0;
  top: 64px;
  height: calc(100% - 64px);
  z-index: 9;
  width: 413px;
  background: $color-white;
  box-shadow: 0 1px 15px 0 rgba(0,0,0,.05);
  @include media-lte(tablet) {
    width: 320px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-text-black-2;
    color: $color-white;
    text-align: center;
    padding: 10px;
    height: 50px;
    width: 100%;
  }

  &__close-icon {
    position: absolute;
    right: 20px;
    top: 19px;
    z-index: 2;
    cursor: pointer;
  }

  &__content {
    padding: 20px 15px 100px 15px;
  }

  &__item {
    margin-bottom: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid $color-solitude;
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__item-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item-date {
    color: $color-gray-3;
    font-size: 13px;
  }

  &__item-type {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: .4px;
  }

  &__btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px;
    background-color: $color-white;
  }
}
</style>
