import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "AppLayoutDefault app-layout-default" }
const _hoisted_2 = { class: "app-layout-default__main" }
const _hoisted_3 = { class: "app-layout-default__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NotificationSidebar = _resolveComponent("NotificationSidebar")!
  const _component_TheFooter = _resolveComponent("TheFooter")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", {
      class: _normalizeClass(["app-layout-default__header", { fixed: _ctx.isFixed }])
    }, [
      _createVNode(_component_TheHeader)
    ], 2),
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_router_view),
      _createVNode(_Transition, {
        name: "transition-translate-right",
        appear: ""
      }, {
        default: _withCtx(() => [
          (_ctx.isNotificationSidebarOpen)
            ? (_openBlock(), _createBlock(_component_NotificationSidebar, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("footer", _hoisted_3, [
      _createVNode(_component_TheFooter, { class: "app-layout-default__footer-item" })
    ])
  ]))
}