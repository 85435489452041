import {
  fetchGetTasksList,
  fetchGetTaskById,
  fetchGetTaskNext,
  fetchSetTaskComplete,
  fetchGetTaskPandadocLink,
  fetchSetCreateTask,
  fetchGetTagsList,
  fetchGetTaskPandadocHTML,
  fetchSetSendDoc,
  fetchSetSendArchive,
} from '@/store';
import {
  ITaskList, ITaskById, ITaskPandadocLink, ICreateTask,
} from '@/types/api/tasks';
import { generalErrorHandling } from '@/helpers/generalErrorHandling';
import { ref } from 'vue';

const isGetTasksListLoading = ref(false);
const isGetTaskByIdLoading = ref(false);
const isGetTaskNextLoading = ref(false);
const isSetTaskCompleteLoading = ref(false);
const isGetTaskPandadocLinkLoading = ref(false);
const isSetCreateTaskLoading = ref(false);
const isGetTagsListLoading = ref(false);
const isGetTagsListSortByTagsLoading = ref(false);
const isGetTaskPandadocHTMLLoading = ref(false);
const isSetSendDocLoading = ref(false);
const isSetSendArchiveLoading = ref(false);

const isGetTasksListError = ref(false);
const isGetTaskByIdError = ref(false);
const isGetTaskNextError = ref(false);
const isSetTaskCompleteError = ref(false);
const isGetTaskPandadocLinkError = ref(false);
const isSetCreateTaskError = ref(false);
const isGetTagsListError = ref(false);
const isGetTaskPandadocHTMLError = ref(false);
const isSetSendDocError = ref(false);
const isSetSendArchiveError = ref(false);

const getTasksListData = ref<ITaskList[]>();
const getTaskByIdData = ref<ITaskById>();
const getTaskNextData = ref<ITaskById>();
const setTaskCompleteData = ref();
const getTaskPandadocLinkData = ref<ITaskPandadocLink>();
const setCreateTaskData = ref<ITaskById>();
const getTagsListData = ref<string[]>([]);
const getTasksListDataSortedByTags = ref<ITaskList[]>();
const getTaskPandadocHTMLData = ref();
const setSendDocData = ref<ITaskPandadocLink>();
const setSendArchiveData = ref<ITaskPandadocLink>();

export const useTasks = () => {
  const getTasksList = async (transactionId: number) => {
    isGetTasksListLoading.value = true;
    isGetTasksListError.value = false;
    const response = await fetchGetTasksList(transactionId).catch((error: Response) => {
      isGetTasksListError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      getTasksListData.value = response.data.filter((item) => (
        item.task_template.task_type !== 'fill_property'));
    }
    isGetTasksListLoading.value = false;
  };

  const getTaskById = async (id: number, transactionId: number) => {
    isGetTaskByIdLoading.value = true;
    isGetTaskByIdError.value = false;
    const response = await fetchGetTaskById(id, transactionId).catch((error: Response) => {
      isGetTaskByIdError.value = true;
      void generalErrorHandling(error);
    });
    if (response) getTaskByIdData.value = response;
    isGetTaskByIdLoading.value = false;
  };

  const getTaskNext = async (transactionId: number) => {
    isGetTaskNextLoading.value = true;
    isGetTaskNextError.value = false;
    const response = await fetchGetTaskNext(transactionId).catch((error: Response) => {
      isGetTaskNextError.value = true;
      void generalErrorHandling(error);
    });
    if (response) getTaskNextData.value = response;
    isGetTaskNextLoading.value = false;
  };

  const setTaskComplete = async (id: number, action? : string) => {
    isSetTaskCompleteLoading.value = true;
    isSetTaskCompleteError.value = false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await fetchSetTaskComplete(id, action).catch((error: Response) => {
      isSetTaskCompleteError.value = true;
      void generalErrorHandling(error);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (response) setTaskCompleteData.value = response;
    isSetTaskCompleteLoading.value = false;
  };

  const getTaskPandadocLink = async (id: number, action? : string, prefill? : string) => {
    isGetTaskPandadocLinkLoading.value = true;
    isGetTaskPandadocLinkError.value = false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await fetchGetTaskPandadocLink(id, action, prefill).catch((error: Response) => {
      isGetTaskPandadocLinkError.value = true;
      void generalErrorHandling(error);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (response) getTaskPandadocLinkData.value = response;
    isGetTaskPandadocLinkLoading.value = false;
  };

  const setCreateTask = async (transactionId: number, data: ICreateTask) => {
    isSetCreateTaskLoading.value = true;
    isSetCreateTaskError.value = false;
    const response = await fetchSetCreateTask(transactionId, data).catch((error: Response) => {
      isSetCreateTaskError.value = true;
      void generalErrorHandling(error);
    });
    if (response) setCreateTaskData.value = response;
    isSetCreateTaskLoading.value = false;
  };

  const getTagsList = async (transactionId: number) => {
    isGetTagsListLoading.value = true;
    isGetTagsListError.value = false;
    const response = await fetchGetTagsList(transactionId).catch((error: Response) => {
      isGetTagsListError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      getTagsListData.value = response.data;
    }
    isGetTagsListLoading.value = false;
  };

  const getTasksFilterByTagList = (tags: string[]) => {
    isGetTagsListSortByTagsLoading.value = true;
    getTasksListDataSortedByTags.value = getTasksListData.value?.filter((item) => (
      item.task_template.tags.some((tag) => tags.includes(tag))));
    isGetTagsListSortByTagsLoading.value = false;
  };

  const getTaskPandadocHTML = async (id: number) => {
    isGetTaskPandadocHTMLLoading.value = true;
    isGetTaskPandadocHTMLError.value = false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await fetchGetTaskPandadocHTML(id).catch((error: Response) => {
      isGetTaskPandadocHTMLError.value = true;
      void generalErrorHandling(error);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (response) getTaskPandadocHTMLData.value = response;
    isGetTaskPandadocHTMLLoading.value = false;
  };

  const sendDoc = async (task_id: number, transaction_id: number, email: string) => {
    isSetSendDocLoading.value = true;
    isSetSendDocError.value = false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await fetchSetSendDoc(task_id, transaction_id, email).catch((error: Response) => {
      isSetSendDocError.value = true;
      void generalErrorHandling(error);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (response) setSendDocData.value = response;
    isSetSendDocLoading.value = false;
  };

  const sendArchive = async (transaction_id: number, email: string) => {
    isSetSendArchiveLoading.value = true;
    isSetSendArchiveError.value = false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await fetchSetSendArchive(transaction_id, email).catch((error: Response) => {
      isSetSendArchiveError.value = true;
      void generalErrorHandling(error);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (response) setSendArchiveData.value = response;
    isSetSendArchiveLoading.value = false;
  };

  const resetHTMLDOC = () => {
    getTaskPandadocHTMLData.value = undefined;
  };

  const resetTasksAll = () => {
    getTasksListData.value = undefined;
    getTaskByIdData.value = undefined;
    getTaskNextData.value = undefined;
    setTaskCompleteData.value = ref();
    getTaskPandadocLinkData.value = undefined;
    setCreateTaskData.value = undefined;
    getTagsListData.value = [];
    getTasksListDataSortedByTags.value = undefined;
    getTaskPandadocHTMLData.value = undefined;
    setSendDocData.value = undefined;
    setSendArchiveData.value = undefined;
  };

  return {
    getTasksList,
    getTaskById,
    getTaskNext,
    setTaskComplete,
    getTaskPandadocLink,
    setCreateTask,
    getTagsList,
    getTasksFilterByTagList,
    resetTasksAll,
    getTaskPandadocHTML,
    resetHTMLDOC,
    sendDoc,
    sendArchive,

    isGetTasksListLoading,
    isGetTaskByIdLoading,
    isGetTaskNextLoading,
    isSetTaskCompleteLoading,
    isGetTaskPandadocLinkLoading,
    isSetCreateTaskLoading,
    isGetTagsListLoading,
    isGetTagsListSortByTagsLoading,
    isGetTaskPandadocHTMLLoading,
    isSetSendDocLoading,
    isSetSendArchiveLoading,

    isGetTasksListError,
    isGetTaskByIdError,
    isGetTaskNextError,
    isSetTaskCompleteError,
    isGetTaskPandadocLinkError,
    isSetCreateTaskError,
    isGetTagsListError,
    isGetTaskPandadocHTMLError,
    isSetSendDocError,
    isSetSendArchiveError,

    getTasksListData,
    getTaskByIdData,
    getTaskNextData,
    setTaskCompleteData,
    getTaskPandadocLinkData,
    setCreateTaskData,
    getTagsListData,
    getTasksListDataSortedByTags,
    getTaskPandadocHTMLData,
    setSendDocData,
    setSendArchiveData,
  };
};
