import env from '@/config';
import { IUserData } from '@/types/api/user';

const { BACKEND_URL } = env;

export const fetchGetUserInfo = () => {
  const path = `${BACKEND_URL}/user`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<IUserData>;
  });
};

export const fetchSetUserInfo = (userData: object) => {
  const path = `${BACKEND_URL}/user`;

  const body = JSON.stringify({
    ...userData,
  });

  const data = {
    method: 'PATCH',
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<IUserData>;
  });
};
