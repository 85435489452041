import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WdSkeleton = _resolveComponent("WdSkeleton")!
  const _component_DefaultAvatar = _resolveComponent("DefaultAvatar")!
  const _component_BaseDropdown = _resolveComponent("BaseDropdown")!
  const _component_HeaderCard = _resolveComponent("HeaderCard")!

  return (_openBlock(), _createBlock(_component_HeaderCard, { class: "HeaderProfile header-profile" }, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_WdSkeleton, {
            key: 0,
            height: "33px",
            width: "142px",
            class: "header-profile__skeleton"
          }))
        : (_openBlock(), _createBlock(_component_BaseDropdown, {
            key: 1,
            light: "",
            "dropdown-right": "",
            "no-scrollbar": "",
            "min-items-width": 180,
            class: "header-profile__dropdown"
          }, {
            selected: _withCtx(() => [
              _createVNode(_component_DefaultAvatar, {
                class: "header-profile__icon-wrap",
                "user-letter": _ctx.userLetter
              }, null, 8, ["user-letter"]),
              _createElementVNode("span", {
                class: "header-profile__value is--body-text-1",
                textContent: _toDisplayString(_ctx.userName)
              }, null, 8, _hoisted_1)
            ]),
            listItem: _withCtx(() => [
              _createElementVNode("a", {
                href: `${_ctx.env.FRONTEND_APP_URL}/profile/settings`,
                class: "header-profile__profile is--body-text-1",
                textContent: 'Profile Settings'
              }, null, 8, _hoisted_2),
              _createElementVNode("a", {
                href: `${_ctx.env.FRONTEND_APP_URL}/settings`,
                class: "header-profile__profile is--body-text-1",
                textContent: 'Change Password'
              }, null, 8, _hoisted_3),
              (_ctx.isSubscription)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: `${_ctx.env.FRONTEND_APP_URL}/pricing`,
                    class: "header-profile__profile is--body-text-1",
                    textContent: 'Subscription'
                  }, null, 8, _hoisted_4))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "header-profile__profile is--body-text-1",
                "data-testid": "logout-btn",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logoutHandler && _ctx.logoutHandler(...args))),
                textContent: 'Log Out'
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}