import {
  Router,
  createRouter as createVueRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';
import { computed } from 'vue';
import { createRoutes } from './routes';
import { useCore } from '@/store';
import { useGeneral } from '@/composable';

const { getInitData } = useGeneral();

// eslint-disable-next-line
export const beforeEach = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext, isAuth: boolean, router: any) => {
  next();
};


export const createRouter = (): Router => {
  const router = createVueRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [],
    scrollBehavior(to, from, savedPosition) {
      if (to.name === from.name) {
        return void 0;
      }

      if (savedPosition) {
        return savedPosition;
      }

      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth',
        };
      }

      return { top: 0 };
    },
  });

  const routes = createRoutes({ router });
  routes.forEach((route) => router.addRoute(route));

  const { person } = useCore();

  router.beforeEach((to, from, next) => {
    const isAuth = computed(() => person.value.loggedIn);
    if (!from.name) {
      void getInitData();
    }

    beforeEach(to, from, next, isAuth.value, router);
  });

  return router;
};
