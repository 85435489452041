import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.disabled ? 'span' : 'router-link'), {
    class: "Logo logo",
    to: _ctx.to,
    exact: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        class: "logo__desktop",
        src: _ctx.logoIcon,
        alt: "logo",
        width: "250",
        height: "30"
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["to"]))
}