import { RouteRecordRaw } from 'vue-router';
import { ROUTE_ERROR_404 } from '@/helpers/enums/routes';

const createRoutes = (): RouteRecordRaw[] => [
  {
    path: '/:path_match(.*)*',
    name: ROUTE_ERROR_404,
    props: ({ params: p }) => ({ ...p, error_code: 404 }),
    component: () => import(
      './ViewErrors.vue'
    ),
    meta: {
      auth: false,
    },
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createModule = (options: unknown) => {
  const routes = createRoutes();
  return routes;
};

export default createModule;
