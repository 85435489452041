import env from '@/config';
import { ICreateLinkBody, ICreateLinkResponse, IFileListResponse } from '@/types/api/filer';

const { FILER_URL } = env;

export const fetchCreateLink = (linkData: ICreateLinkBody) => {
  const path = `${FILER_URL}/file/link/upload`;

  const body = JSON.stringify({
    mime: linkData.mime,
    name: linkData.name,
    'object-id': +linkData.task_id,
    'object-name': 'task',
    'object-type': 'many2many',
    'object-data': { type: 'other' },
  });

  const data = {
    method: 'POST',
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<ICreateLinkResponse>;
  });
};

export const fetchUploadToLink = (url: string, file: File, type: string, task_id: string, file_id: string) => {
  const headers = {
    'x-goog-meta-file-id': file_id,
    'x-goog-meta-object-id': task_id,
    'x-goog-meta-object-name': 'task',
    'x-goog-meta-object-type': 'many2many',
    'x-goog-meta-object-data': JSON.stringify({ type: 'other' }),
    'Content-Type': type,
  };

  const formData = new FormData();
  formData.append('file', file);

  const data = {
    method: 'PUT',
    body: file,
    headers: headers as Record<string, string>,
  };

  return fetch(url, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response;
  });
};

export const upload = (file: File, type: string, url: string, task_id: string, file_id: string) => (
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', type);
    xhr.setRequestHeader('x-goog-meta-file-id', file_id);
    xhr.setRequestHeader('x-goog-meta-object-id', task_id);
    xhr.setRequestHeader('x-goog-meta-object-name', 'task');
    xhr.setRequestHeader('x-goog-meta-object-type', 'many2many');
    xhr.setRequestHeader('x-goog-meta-object-data', JSON.stringify({ type: 'other' }));
    xhr.setRequestHeader('origin', 'https://dev.yoursunday.pro');
    xhr.send(file);

    xhr.onload = function () {
      if (xhr.status === 200) {
        resolve('success');
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('failed');
      }
    };

    xhr.onerror = function () {
      // eslint-disable-next-line no-console
      console.log('onerror', xhr);
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('failed');
    };
  })
);

export const fetchGetFilesList = (task_id: number) => {
  const path = `${FILER_URL}/file/task/${task_id}`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<IFileListResponse>;
  });
};

export const fetchGetFileById = (file_id: number) => {
  const path = `${FILER_URL}/file/${file_id}/link/download`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<ICreateLinkResponse>;
  });
};

export const fetchDeleteFileById = (file_id: number) => {
  const path = `${FILER_URL}/file/${file_id}`;

  const data = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json();
  });
};
