import { IMarkdownOptions } from '@/types/api/blog';

export default function parse(markdown: string) {
  const separator = '---';
  const topOptions = {} as IMarkdownOptions;
  const topOptionsStr = markdown.substring(markdown.indexOf(separator) + 3, markdown.lastIndexOf(separator));
  const temp = topOptionsStr.trim().split('\',\n').map((item) => item.replaceAll('\'', '').split(':')) as [];
  const content = markdown.replace(topOptionsStr, '').replaceAll(separator, '').trim();

  temp.forEach((item: string[]) => {
    topOptions[item[0] as keyof IMarkdownOptions] = item[1].trim();
  });

  return {
    content,
    ...topOptions,
  };
}
