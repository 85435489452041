<template>
  <transition name="fade-change">
    <div
      v-if="isShowPopup"
      class="CookieConsent cookie-consent"
      :style="{ maxWidth: `${maxWidthContainer}px` }"
    >
      <div class="cookie-consent__wrapper">
        <p class="cookie-consent__text is--body-text-1">
          {{ message }}
        </p>
        <div class="cookie-consent__buttons">
          <BaseButton
            secondary
            small
            class="cookie-consent__button"
            :href="linkHref"
          >
            {{ linkText }}
          </BaseButton>
          <BaseButton
            small
            class="cookie-consent__button"
            @click="dismissCookie"
          >
            {{ dismissButtonText }}
          </BaseButton>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount } from 'vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default defineComponent({
  name: 'CookieConsent',
  components: {
    BaseButton,
  },
  props: {
    maxWidthContainer: {
      type: Number,
      default: 700,
    },
    linkText: {
      type: String,
      default: 'Cookie Policy',
    },
    linkHref: {
      type: String,
      default: '/cookie-policy',
    },
    message: {
      type: String,
      default: 'This site uses cookies to deliver its services and analyze traffic.',
    },
    dismissButtonText: {
      type: String,
      default: 'Ok. Got it.',
    },
    cookieName: {
      type: String,
      default: 'cookieconsent_status',
    },
    cookiePath: {
      type: String,
      default: '/',
    },
    cookieDomain: {
      type: String,
      default: '',
    },
    cookieExpiryDays: {
      type: Number,
      default: 365,
    },
  },
  emits: ['dismiss-cookie'],
  setup(props, { emit }) {
    const isShowPopup = ref(true);

    function escapeRegular(str: string) {
      return str.replace(/[.*+?^$|[\](){}\\-]/g, '\\$&');
    }

    function getCookie(key: string, decoder = decodeURIComponent) {
      if ((typeof key !== 'string') || !key) {
        return null;
      }
      const reKey = new RegExp(`(?:^|; )${escapeRegular(key)}(?:=([^;]*))?(?:;|$)`);
      const match = reKey.exec(document.cookie);
      if (match === null) {
        return null;
      }
      return typeof decoder === 'function' ? decoder(match[1]) : match[1];
    }

    function setCookie(name: string, value: number, expiryDays: number, domain: string, path: string) {
      const exdate = new Date();
      exdate.setDate(exdate.getDate() + (expiryDays || 365));
      const cookie = [
        `${name}=${value}`,
        `expires=${exdate.toUTCString()}`,
        `path=${(path || '/')}`,
      ];
      if (domain) {
        cookie.push(`domain=${domain}`);
      }
      document.cookie = cookie.join(';');
    }

    function dismissCookie() {
      isShowPopup.value = false;
      // eslint-disable-next-line vue/custom-event-name-casing
      emit('dismiss-cookie', isShowPopup.value);
      setCookie(props.cookieName, 1, props.cookieExpiryDays, props.cookieDomain, props.cookiePath);
    }

    onBeforeMount(() => {
      isShowPopup.value = !getCookie(props.cookieName);
    });
    return {
      isShowPopup,
      dismissCookie,
    };
  },
});
</script>

<style lang="scss">
.cookie-consent {
  position: fixed;
  bottom: 30px;
  z-index: 999;
  box-shadow: $medium-shadow;
  background: $accent40;
  border-radius: 20px;
  border: 1px solid $accent20;

  @media screen and (min-width: 768px) {
    transform: translate(-50%, 0);
    left: 50%;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: calc(100% - 30px);
    bottom: 15px;
    left: 15px;
    max-width: 100% !important;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    border-radius: 2px;
    @media screen and (max-width: 767px) {
      padding: 10px;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__text {
    margin: 0;
    @media screen and (max-width: 767px) {
      margin-bottom: 5px;
      text-align: center;
    }
  }

  &__buttons {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;

    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }

  &__button {
    flex-shrink: 0;
    & + & {
      margin-left: 5px;
    }
  }
}
.fade-change-leave-active {
  transition: transform 0.3s ease-in-out;
}
.fade-change-enter-active {
  transition: opacity 0.4s ease-in-out 0.3s;
}
.fade-change-enter-to {
  opacity: 1;
}
.fade-change-enter-from,
.fade-change-leave-to {
  opacity: 0;
}
</style>
