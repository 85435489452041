import { ref } from 'vue';
import {
  useCore,
  fetchGetUserInfo,
  fetchSetUserInfo,
} from '@/store';
import { generalErrorHandling } from '@/helpers/generalErrorHandling';
import { IUserData } from '@/types/api/user';

const isGetUserInfoLoading = ref(false);
const isSetUserInfoLoading = ref(false);

const isGetUserInfoError = ref(false);
const isSetUserInfoError = ref(false);

const getUserInfoData = ref<IUserData>();
const setUserInfoData = ref<IUserData>();

export const useUsers = () => {
  const { person } = useCore();

  const getUserInfo = async () => {
    isGetUserInfoLoading.value = true;
    isGetUserInfoError.value = false;
    const response = await fetchGetUserInfo().catch((error: Response) => {
      isGetUserInfoError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      getUserInfoData.value = response;
      person.value.setUserAPIData(response);
    }
    isGetUserInfoLoading.value = false;
  };

  const setUserInfo = async (data: object) => {
    isSetUserInfoLoading.value = true;
    isSetUserInfoError.value = false;
    const response = await fetchSetUserInfo(data).catch((error: Response) => {
      isSetUserInfoError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      setUserInfoData.value = response;
      person.value.setUserAPIData(response);
    }
    isSetUserInfoLoading.value = false;
  };

  return {
    getUserInfo,
    setUserInfo,

    isGetUserInfoLoading,
    isSetUserInfoLoading,

    isGetUserInfoError,
    isSetUserInfoError,

    getUserInfoData,
    setUserInfoData,
  };
};
