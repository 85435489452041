<template>
  <div
    class="Header header is--container"
    :class="{ 'is--open-mobile-menu': isMobileMenuOpen }"
  >
    <div class="header__logo-menu-wrap">
      <Logo class="header-logo" />
    </div>

    <ul class="header__nav">
      <li
        v-for="menuItem in MENU_HEADER"
        :key="menuItem.text"
        class="header-nav__item"
        :class="getActive(menuItem.name)"
      >
        <router-link
          v-if="menuItem.name"
          :to="{ name: menuItem.name }"
          class="header-nav__item-link is--body-text-1"
          v-text="menuItem.text"
        />
        <a
          v-if="menuItem.href"
          :href="menuItem.href"
          target="_blank"
          class="header-nav__item-link is--body-text-1"
          v-text="menuItem.text "
        />
      </li>
    </ul>

    <div class="header__right">
      <div class="header-nav__right">
        <div
          class="header-btns"
        >
          <BaseButton
            v-if="!isLoggedIn"
            :href="`${env.FRONTEND_APP_URL}/signin`"
            class="header-btns__sign-in"
            secondary
            small
            data-testid="base-button-login"
            @click="signInHandler"
            v-text="'Log In'"
          />

          <BaseButton
            v-if="!isLoggedIn"
            :href="`${env.FRONTEND_APP_URL}/`"
            class="header-btns__sign-up"
            small
            @click="signUpHandler"
            v-text="'Sign Up'"
          />
        </div>

        <HeaderProfile
          v-if="isLoggedIn"
          class="header-btns__profile"
        />
      </div>

      <MobileMenu
        v-model="isMobileMenuOpen"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  watch,
  defineAsyncComponent,
} from 'vue';
import { MENU_HEADER } from '@/config/menu';
import { useRouter } from 'vue-router';
import { useCore } from '@/store';
import Logo from '@/components/common/Logo.vue';
import MobileMenu from './MobileMenu.vue';
import HeaderProfile from './HeaderProfile.vue';
import { gtagClick } from '@/helpers/gtag';
import env from '@/config';

// Because component in v-if block - make it async
const BaseButton = defineAsyncComponent(() => import(
  /* webpackChunkName: "BaseButton" */
  '@/components/ui/BaseButton.vue'
));

export default defineComponent({
  name: 'TheHeader',
  components: {
    Logo,
    BaseButton,
    MobileMenu,
    HeaderProfile,
  },
  setup() {
    const isMobileMenuOpen = ref(false);
    const router = useRouter();
    const { person } = useCore();

    const isLoggedIn = computed(() => person.value.loggedIn);
    const signInHandler = () => {
      gtagClick('Landing Header Login Click');
    };

    const signUpHandler = () => {
      gtagClick('Landing Header Signup Click');
    };

    const getActive = (name: string) => {
      if (router.currentRoute.value.name === name) {
        return 'is--active';
      }
      return '';
    };

    watch([router.currentRoute], () => {
      isMobileMenuOpen.value = false;
    });

    return {
      isMobileMenuOpen,
      isLoggedIn,
      signInHandler,
      signUpHandler,
      getActive,
      MENU_HEADER,
      env,
    };
  },
});
</script>

<style lang="scss">
.header {
  position: relative;
  z-index: $z-index-header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $header-height;
  margin: 0 auto;

  &.is--open-mobile-menu {
    box-shadow: $small-shadow;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__logo-menu-wrap {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__bell {
    position: relative;
    display: none;
    align-items: center;
    margin-right: 15px;
    @include media-lte(desktop-md) {
      display: flex;
    }
  }

  &__bell-count {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -8px;
    left: 12px;
    width: 18px;
    height: 18px;
    font-size: 11px;
    color: $color-white;
    border-radius: 100%;
    background-color: $color-valencia;
    z-index: 2;
  }
}

.header-logo {
  display: flex;
  align-items: center;

  @include media-lte(desktop-lg) {
    max-width: 220px;
    margin-right: 30px;
  }
}

.header-menu {
  align-items: center;
  transition: all 0.3s;

  @include media-lte(desktop-md) {
    display: none;
  }
}

.header-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 24px;

  @include media(tablet) {
    font-size: 16px;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__item {
    color: $grayscale90;

    &:not(:last-of-type) {
      margin-right: 60px;
      @include media-lte(desktop-lg) {
        margin-right: 30px;
      }
    }
    @include media-lte(desktop-md) {
      display: none;
    }

    &.is--active {
      text-decoration: underline;
    }
  }

  &__item-link {
    color: inherit;
  }
}

.header-btns {
  min-height: 34px;

  &__sign-up {
    margin-left: 12px;
  }

  @include media-lte(desktop-md) {
    display: none;
  }


  &__profile {
    @include media-lte(desktop-md) {
      display: none;
    }
  }
}
</style>
