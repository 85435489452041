<template>
  <section class="AppLayoutDefault app-layout-default">
    <header class="app-layout-default__header" :class="{ fixed: isFixed }">
      <TheHeader />
    </header>

    <main class="app-layout-default__main">
      <router-view />
      <transition name="transition-translate-right" appear>
        <NotificationSidebar v-if="isNotificationSidebarOpen" />
      </transition>
    </main>

    <footer
      class="app-layout-default__footer"
    >
      <TheFooter
        class="app-layout-default__footer-item"
      />
    </footer>


    <!-- <TheConfetti
      v-if="isSold"
      :init="isSold"
    /> -->
  </section>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  computed, defineAsyncComponent,
} from 'vue';

import { useNotifications } from '@/composable';

import TheHeader from './components/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';
import NotificationSidebar from '@/components/common/NotificationSidebar.vue';
import { useCore } from '@/store';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'AppLayoutDefault',
  components: {
    TheHeader,
    TheFooter,
    NotificationSidebar,
  },
  setup() {
    const isFixed = ref(false);
    const { selectedTransaction } = useCore();
    const router = useRouter();
    const { currentRoute } = router;
    const isSold = computed(() => selectedTransaction.value.isSoldTransaction);

    const { isNotificationSidebarOpen } = useNotifications();

    function setScrollPosition() {
      const scrollPosition = window.pageYOffset;

      if (scrollPosition > 0) {
        isFixed.value = true;
      } else {
        isFixed.value = false;
      }
    }

    onBeforeMount(() => {
      window.addEventListener('scroll', setScrollPosition);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', setScrollPosition);
    });

    return {
      isNotificationSidebarOpen,
      isFixed,
      isSold,
    };
  },
});
</script>

<style lang="scss">
.app-layout-default {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 100%;
  padding-top: $header-height;
  background: $secondaryPink;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 100;
    background: transparent;
    transition: all .12s ease-in-out;

    &.fixed {
      position: fixed;
      background: $grayscale-white;
      box-shadow: $small-shadow;
    }

    @include media-lte(desktop-md) {
      background: $grayscale-white;
      box-shadow: $small-shadow;
    }
  }

  &__main {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    width: 100%;
  }

  &__footer {
    width: 100%;
    position: relative;
    z-index: $z-index-footer;
    padding-top: 150px;
    min-height: $footer-height;
    @include media-lte(tablet) {
      padding-top: 100px;
    }
  }

  &__footer-item {
    margin-bottom: 50px;
  }
}
</style>
