interface IEnv {
  ENV: string;
  KRATOS_URL: string;
  PLAID_URL: string;
  FRONTEND_URL: string;
  FRONTEND_APP_URL: string;
  NOTIFICATION_URL: string;
  BACKEND_URL: string;
  CHATBOT_URL: string;
  FILER_URL: string;
  DEFAULT_TRANSACTION: number;
  PAYMENTS_URL: string;
  GOOGLE_ANALYTICS: string;
  TYPEFORM_LEAVE_FEEDBACK_ID: string;
  HUBSPOT_FORM: string;
  HUBSPOT_PORTAL_ID: string;
  HUBSPOT_FORM_ID: {
    SUBSCRIBE: string;
    CONTACT_US: string;
    SELLING_TIPS: string;
    SALES_INSIGHTS:string;
  };
}

const env = {
  ENV: process.env.VUE_APP_ENV,
  KRATOS_URL: process.env.VUE_APP_KRATOS_URL,
  PLAID_URL: process.env.VUE_APP_PLAID_URL,
  FRONTEND_URL: process.env.VUE_APP_FRONTEND,
  FRONTEND_APP_URL: process.env.VUE_APP_FRONTEND_APP,
  NOTIFICATION_URL: process.env.VUE_APP_NOTIFICATION_URL,
  BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
  CHATBOT_URL: process.env.VUE_APP_CHATBOT_URL,
  FILER_URL: process.env.VUE_APP_FILER_URL,
  MAP_API_KEY: process.env.VUE_APP_MAP_API_KEY,
  DEFAULT_TRANSACTION: Number(process.env.VUE_APP_DEFAULT_TRANSACTION),
  PAYMENTS_URL: process.env.VUE_APP_PAYMENTS_URL,
  GOOGLE_ANALYTICS: process.env.VUE_APP_GOOGLE_ANALYTICS,
  TYPEFORM_LEAVE_FEEDBACK_ID: process.env.VUE_APP_TYPEFORM_LEAVE_FEEDBACK_ID,
  HUBSPOT_FORM: process.env.VUE_APP_HUBSPOT_FORM,
  HUBSPOT_PORTAL_ID: process.env.VUE_APP_HUBSPOT_PORTAL_ID,
  HUBSPOT_FORM_ID: {
    SUBSCRIBE: process.env.VUE_APP_HUBSPOT_FORM_ID_SUBSCRIBE,
    CONTACT_US: process.env.VUE_APP_HUBSPOT_FORM_ID_CONTACT_US,
    SELLING_TIPS: process.env.VUE_APP_HUBSPOT_FORM_ID_SELLING_TIPS,
    SALES_INSIGHTS: process.env.VUE_APP_HUBSPOT_FORM_ID_SALES_INSIGHTS,
  },
} as IEnv;

export const seo = {
  defaultTitle: 'Welcome to Sunday',
  defaultDesc: 'Welcome to Sunday, a real estate transaction platform powered by you.',
  defaultImage: 'https://storage.googleapis.com/yoursunday-static/static/Logo.svg', // TODO fix
};


export default env;
