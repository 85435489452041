import env from '@/config';
import { IChatBody } from '@/types/api/chat';

const { CHATBOT_URL } = env;

export const fetchPostMessage = (bodyObject: IChatBody) => {
  const path = `${CHATBOT_URL}/v2/message`;

  const body = JSON.stringify(bodyObject);

  const data = {
    method: 'POST',
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response;
  });
};
