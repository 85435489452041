import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "Footer footer" }
const _hoisted_2 = { class: "footer__top" }
const _hoisted_3 = { class: "footer__left" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["href", "textContent"]
const _hoisted_6 = { class: "footer__right" }
const _hoisted_7 = { class: "footer__nav footer-nav" }
const _hoisted_8 = ["href", "textContent"]
const _hoisted_9 = { class: "footer__bottom" }
const _hoisted_10 = ["textContent"]
const _hoisted_11 = { class: "footer__terms is--small-text-1" }
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Logo, { class: "footer__logo" }),
        _createElementVNode("p", {
          class: "footer__description is--body-text-1",
          textContent: _toDisplayString('Sell your home like a pro.')
        }, null, 8, _hoisted_4),
        _createElementVNode("a", {
          class: "footer__contact is--body-text-2",
          href: `mailto:ask@yoursunday.com`,
          textContent: _toDisplayString('ask@yoursunday.com')
        }, null, 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.MENU_FOOTER, (menuItem) => {
            return (_openBlock(), _createElementBlock("li", {
              key: menuItem.text,
              class: "footer-nav__item"
            }, [
              (menuItem.name)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: { name: menuItem.name },
                    class: "is--button",
                    textContent: _toDisplayString(menuItem.text )
                  }, null, 8, ["to", "textContent"]))
                : _createCommentVNode("", true),
              (menuItem.href)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: menuItem.href,
                    target: "_blank",
                    class: "is--button",
                    textContent: _toDisplayString(menuItem.text )
                  }, null, 8, _hoisted_8))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("p", {
        class: "footer__copyright is--small-text-1",
        textContent: _toDisplayString(`© Sunday Inc. ${ _ctx.currentYear }`)
      }, null, 8, _hoisted_10),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("a", {
          class: "footer__terms-item is--small-text-1",
          href: `${_ctx.env.FRONTEND_APP_URL}/privacy-policy`,
          target: "_blank",
          textContent: 'Privacy Policy'
        }, null, 8, _hoisted_12),
        _createElementVNode("a", {
          class: "footer__terms-item is--small-text-1",
          href: `${_ctx.env.FRONTEND_APP_URL}/terms-of-use`,
          target: "_blank",
          textContent: 'Terms of Use'
        }, null, 8, _hoisted_13),
        _createElementVNode("a", {
          class: "footer__terms-item is--small-text-1",
          href: `${_ctx.env.FRONTEND_APP_URL}/cookie-policy`,
          target: "_blank",
          textContent: 'Cookie Policy'
        }, null, 8, _hoisted_14)
      ])
    ])
  ]))
}