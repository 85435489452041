import env, { seo } from '@/config/index';
import { IPageData } from '@/types/pageData.interface';
import { setHSPageView } from '@/helpers/hubspot';


interface ITagItem {
  content: string;
  name?: string;
  property?: string;
}
export const usePageSeo = () => {
  function setMetaContent(key: string, meta: ITagItem) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const element: HTMLElement | null = document.head.querySelector(`meta[${key}="${meta.name || meta.property}"]`);
    if (element instanceof HTMLMetaElement) {
      element.content = meta.content;
    }
  }

  function setTitle(pageData: IPageData) {
    const newTitle = pageData?.seo_title || `${seo.defaultTitle}`;
    document.title = `${newTitle}`;
    const ogTitle = { property: 'og:title', content: document.title };

    setMetaContent('property', ogTitle);
    // hubspot page view
    setHSPageView(window.location.pathname);
  }

  function setRobotsAll() {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const element: HTMLElement | null = document.head.querySelector('meta[robots]');
    if (element instanceof HTMLMetaElement) {
      element.content = 'noindex, nofollow';
    } else {
      const meta = document.createElement('meta');
      meta.name = 'robots';
      meta.content = 'noindex, nofollow';
      document.head.appendChild(meta);
    }
  }

  function setOgImage(pageData: IPageData) {
    // const url = pageData?.thumbnail?.coverage?.url || `${seo.defaultImage}`;
    const url = `${seo.defaultImage}`;
    const ogImage = { property: 'og:image', content: url };

    setMetaContent('property', ogImage);
  }

  function setUrl(pageData: IPageData) {
    // const path = pageData?.canonical || `${env.FRONTEND_URL}${window.location.pathname}`;
    const path = `${env.FRONTEND_URL}${window.location.pathname}`;
    const ogUrl = { property: 'og:url', content: path };
    // set canonical url
    let link: HTMLLinkElement | null = document.head.querySelector('link[rel="canonical"]');
    if (!link) {
      link = document.createElement('link');
      if (link instanceof HTMLLinkElement) {
        link = document.createElement('link');
        link.rel = 'canonical';
        document.head.appendChild(link);
      }
    }
    link.href = path;
    // set og:url
    setMetaContent('property', ogUrl);
  }


  function setMetaTags(pageData: IPageData) {
    const seoTags = [
      {
        name: 'description',
        content: pageData?.seo_description || seo.defaultDesc,
      },
      {
        property: 'og:description',
        content: pageData?.seo_description || seo.defaultDesc,
      },
    ];
    seoTags.forEach((singleTag: ITagItem) => {
      Object.keys(singleTag).forEach((key: string) => {
        setMetaContent(key, singleTag);
      });
    });
  }

  function setMetaData(pageData: IPageData) {
    setTitle(pageData);
    setMetaTags(pageData);
    setUrl(pageData);
    setOgImage(pageData);
  }


  return {
    setMetaContent,
    setTitle,
    setOgImage,
    setUrl,
    setMetaTags,
    setMetaData,
    setRobotsAll,
  };
};
