import {
  fetchPostMessage,
} from '@/store';

import {
  IChatBody,
  IChatResponse, IStreamItem,
} from '@/types/api/chat';

import { generalErrorHandling } from '@/helpers/generalErrorHandling';
import { ref } from 'vue';

const isPostMessageLoading = ref(false);

const isPostMessageError = ref(false);

const postMessageResponseData = ref<IChatResponse>();
const postMessageStreamData = ref<string>();
const postMessageStreamId = ref<string>();
const postMessageDoneStreamId = ref<string>('');


export const useChat = () => {
  const postMessage = async (body: IChatBody) => {
    isPostMessageLoading.value = true;
    isPostMessageError.value = false;
    postMessageStreamData.value = undefined;
    const response = await fetchPostMessage(body).catch((error: Response) => {
      isPostMessageError.value = true;
      void generalErrorHandling(error);
    });
    // handle stream data
    if (response && response.body) {
      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { value, done } = await reader.read();
        if (done) break;
        if (!value || (value === '{}')) return;
        const arrayOfValue = value.split('\n');
        arrayOfValue.forEach((item: string) => {
          if (!item) return;
          const itemParsed = JSON.parse(item) as IStreamItem;
          const { data } = itemParsed;
          if (data.id) {
            // Process the data message chunk
            const { choices } = data;
            if (choices && choices.length > 0) {
              if (choices[0].finish_reason) {
                postMessageDoneStreamId.value = data.id;
              }
              const { content } = choices[0].delta;
              if (content) {
                setTimeout(() => {
                  postMessageStreamId.value = data.id;
                  postMessageStreamData.value = content.replace(/(?:\r\n|\r|\n)/g, '<br>');
                }, 100);
              }
            }
          }
        });
      }
    }
    isPostMessageLoading.value = false;
  };

  const setChatData = (data: IChatResponse) => {
    postMessageResponseData.value = data;
    postMessageResponseData.value?.messages.forEach((item) => item.message?.replace(/(?:\r\n|\r|\n)/g, '<br>'));
  };

  const resetChatAll = () => {
    postMessageResponseData.value = undefined;
    postMessageStreamData.value = undefined;
    postMessageDoneStreamId.value = '';
    postMessageStreamId.value = '';
  };

  return {
    postMessage,
    resetChatAll,
    setChatData,

    isPostMessageLoading,

    isPostMessageError,

    postMessageResponseData,
    postMessageStreamData,
    postMessageStreamId,
    postMessageDoneStreamId,
  };
};
