<template>
  <!-- eslint-disable vue/no-multiple-template-root -->
  <transition name="transition-fade-out">
    <Loader v-if="globalLoader.isLoading" />
  </transition>

  <WdAppLayoutDefault />

  <notifications position="top right" group="transaction">
    <template #body="props">
      <Notification
        v-bind="props.item"
        :duration="props.item.data.duration"
        @close="props.close"
      />
    </template>
  </notifications>

  <CookieConsent
    :domain="env.FRONTEND_URL"
    link-href="/cookie-policy"
  />
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent } from 'vue';
import { useGlobalLoader } from '@/store';
import env from '@/config';
import Loader from '@/components/common/Loader.vue';
import WdAppLayoutDefault from '@/layouts/AppLayoutDefault.vue';
import CookieConsent from '@/components/common/CookieConsent.vue';
import { usePageSeo } from '@/composable';

const { setRobotsAll } = usePageSeo();

const Notification = defineAsyncComponent(() => import(
  '@/components/common/Notification.vue'
));

const options = defineComponent({
  name: 'App',
  components: {
    CookieConsent,
    WdAppLayoutDefault,
    Notification,
    Loader,
  },
  setup: () => {
    const globalLoader = useGlobalLoader();
    globalLoader.toggle(true);

    if (env.ENV === 'dev') setRobotsAll();

    return {
      globalLoader,
      env,
    };
  },
});

export default options;
</script>
