/* eslint no-underscore-dangle: 0 */

import { shallowReactive } from 'vue';
import { ISession } from '@/types/api/auth';
import { IUser } from '@/types/api/plusApi';
import { storage } from '@/services';
import { IUserData } from '@/types/api/user';

export class Person {
  private static instance: Person;

  public loggedIn = storage.loggedIn.get() ?? false;

  public isLoadingUser = true;

  public session!: ISession | null;

  private user: IUser | null = storage.user.get() ?? null;

  public userData: IUserData | null | undefined;

  public pricePlan: string | null = storage.pricePlan.get() ?? null;

  constructor() {
    // do not set subscribes because missing reactivity,
    // use for that initialize method
  }

  public static buildClass() {
    if (Person.instance) return Person.instance;

    const instance = new Person();

    Person.instance = shallowReactive(instance);

    return Person.instance;
  }

  public updateSession(session: ISession) {
    this.session = session;
    this.loggedIn = session.active;
    this.user = {
      id: session.identity.id,
      email: session.identity.traits.email,
      first_name: session.identity.traits.first_name,
      last_name: session.identity.traits.last_name,
    };
    storage.loggedIn.set(session.active);
    storage.user.set(this.user);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setUserData(data: any) {
    if (this.user) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.user = { ...this.user, ...data };
      storage.user.set(this.user);
    }
  }

  public setUserAPIData(data: IUserData) {
    this.userData = { ...this.userData, ...data };
    this.pricePlan = this.userData.plan;
    storage.pricePlan.set(this.userData.plan);
  }

  public resetAll() {
    this.session = null;
    this.user = null;
    this.loggedIn = false;
    this.userData = null;
    storage.loggedIn.remove();
    storage.user.remove();
    storage.pricePlan.remove();
  }

  public setLoading(val: boolean) {
    this.isLoadingUser = val;
  }

  public get User():IUser | null {
    return this.user ? this.user : null;
  }

  public get isBasicPlanUser() {
    return this.pricePlan === 'personal';
  }

  public get isFreePlanUser() {
    return this.pricePlan === 'free';
  }

  public get isBasicPlusPlanUser() {
    return this.pricePlan === 'basic plus';
  }

  public get isProPlanUser() {
    return this.pricePlan === 'pro';
  }

  public get isPayedPlanUser() {
    return this.isBasicPlusPlanUser || this.isBasicPlanUser || this.isProPlanUser;
  }
}
