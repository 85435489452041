import {
  useAuth, useChat, useTasks, useTransactions, useUsers,
} from '@/composable';
import { useCore } from '@/store';
import env from '@/config';
import { ref, computed, watch } from 'vue';


const isGetAuthLoading = ref(false);
const isGetTransactionsDataLoading = ref(false);

export const useGeneral = () => {
  const { person, socket, selectedTransaction } = useCore();
  const { getUserInfo, isGetUserInfoError } = useUsers();
  const {
    getTransactions, getTransactionById, getEstimate, resetTransactionsAll,
    getEmails, isGetTransactionsError, isGetEstimateError, isGetEmailsError,
    isGetTransactionsByIdError,
  } = useTransactions();
  const {
    getTasksList, getTaskNext, getTagsList, resetTasksAll,
    isGetTasksListError, isGetTaskNextError,
  } = useTasks();
  const {
    getSession: getSessionAuth, getSessionData, isGetSessionError,
    resetAuthAll,
  } = useAuth();
  const { resetChatAll } = useChat();
  const isError = computed(() => (isGetUserInfoError.value || isGetEmailsError.value
    || isGetTransactionsError.value || isGetEstimateError.value
    || isGetTasksListError.value || isGetTaskNextError.value
    || isGetTransactionsByIdError.value));

  const getSession = async () => {
    // get session to know if user is authorized
    await getSessionAuth();
    if (getSessionData.value && !isGetSessionError.value) {
      person.value.updateSession(getSessionData.value);
    } else {
      person.value.resetAll();
      socket.value.disconnect();
      selectedTransaction.value.resetAll();
    }
  };

  const getInitData = async () => {
    await getSession();
    // void getUserInfo();
  };

  const getTransactionsData = async () => {
    isGetTransactionsDataLoading.value = true;
    await getTransactions();
    if (selectedTransaction.value.transactionId !== env.DEFAULT_TRANSACTION) {
      void getTransactionById(selectedTransaction.value.transactionId);
    }
    if (!selectedTransaction.value.isNeedToCreateProperty && !selectedTransaction.value.isSoldTransaction) {
      void getTaskNext(selectedTransaction.value.transactionId);
    }
    if (!selectedTransaction.value.isNeedToCreateProperty) void getEstimate(selectedTransaction.value.transactionId);
    void getTasksList(selectedTransaction.value.transactionId);
    // void getTagsList(selectedTransaction.value.transactionId);
    void getEmails(selectedTransaction.value.transactionId);
    isGetTransactionsDataLoading.value = false;
  };

  const getAuthData = async () => {
    isGetAuthLoading.value = true;
    void resetTasksAll();
    await getUserInfo();
    void getTransactionsData();
    isGetAuthLoading.value = false;
  };

  const refreshTransactionData = () => {
    if (selectedTransaction.value.transactionId !== env.DEFAULT_TRANSACTION) {
      void getTransactionById(selectedTransaction.value.transactionId);
    }
    if (!selectedTransaction.value.isNeedToCreateProperty && !selectedTransaction.value.isSoldTransaction) {
      void getTaskNext(selectedTransaction.value.transactionId);
    }
    void getTasksList(selectedTransaction.value.transactionId);
    void getEmails(selectedTransaction.value.transactionId);
  };

  const resetAll = () => {
    resetTasksAll();
    resetTransactionsAll();
    resetAuthAll();
    resetChatAll();
  };

  const resetLoading = () => {
    isGetAuthLoading.value = false;
    isGetTransactionsDataLoading.value = false;
  };

  watch(() => isError.value, () => {
    resetLoading();
  });

  return {
    getInitData,
    getAuthData,
    refreshTransactionData,
    resetAll,
    getTransactionsData,
    resetLoading,

    isGetAuthLoading,
    isGetTransactionsDataLoading,
  };
};
