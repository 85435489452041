import {
  ICreateTask,
  ITaskList,
  ITaskById,
  ITaskPandadocLink,
} from '@/types/api/tasks';
import env from '@/config';
import { useCore } from '@/store';

const { BACKEND_URL } = env;
const { person } = useCore();

export const fetchGetTasksList = (transactionId: number, status = 'all') => {
  const path = `${BACKEND_URL}/user/tasks?transaction_id=${transactionId}&status=${status}`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: person.value.loggedIn ? 'include' as RequestCredentials : 'omit' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<{ data: ITaskList[] }>;
  });
};


export const fetchGetTaskById = (id: number, transactionId: number) => {
  const path = `${BACKEND_URL}/user/tasks/${id}?transaction_id=${transactionId}`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<ITaskById>;
  });
};

export const fetchGetTaskNext = (transactionId: number) => {
  const path = `${BACKEND_URL}/user/tasks/next?transaction_id=${transactionId}`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<ITaskById>;
  });
};

export const fetchSetTaskComplete = (id: number, action = 'complete') => {
  const path = `${BACKEND_URL}/user/tasks/complete`;

  const body = JSON.stringify({
    id,
    action,
  });

  const data = {
    method: 'PUT',
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json();
  });
};

export const fetchGetTaskPandadocLink = (id: number, action = 'send', prefill = 'false') => {
  const path = `${BACKEND_URL}/user/tasks/pandadoc?prefill=${prefill}`;

  const body = JSON.stringify({
    task_id: id,
    action,
  });

  const data = {
    method: 'PATCH',
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<ITaskPandadocLink>;
  });
};

export const fetchSetCreateTask = (transactionId: number, bodyData: ICreateTask) => {
  const path = `${BACKEND_URL}/user/tasks?transaction_id=${transactionId}`;

  const body = JSON.stringify({
    ...bodyData,
  });
  const data = {
    method: 'POST',
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<ITaskById>;
  });
};

export const fetchGetTasksSearch = (transactionId: number, search? : string, status = 'all') => {
// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const path = `${BACKEND_URL}/user/tasks?transaction_id=${transactionId}&status=${status}&search=${search}`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<{ data: ITaskList[] }>;
  });
};

export const fetchGetTagsList = (transactionId: number) => {
  const path = `${BACKEND_URL}/user/tags?transaction_id=${transactionId}`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: person.value.loggedIn ? 'include' as RequestCredentials : 'omit' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<{ data: [] }>;
  });
};

export const fetchGetTaskPandadocHTML = (task_id: number) => {
  const path = `${BACKEND_URL}/user/tasks/${task_id}/pandadoc`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.text();
  });
};

export const fetchSetSendDoc = (task_id: number, transaction_id: number, email: string) => {
  const path = `${BACKEND_URL}/user/transactions/${transaction_id}/package?task_id=${task_id}`;

  const body = JSON.stringify({
    email,
  });

  const data = {
    method: 'POST',
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<ITaskPandadocLink>;
  });
};

export const fetchSetSendArchive = (transaction_id: number, email: string) => {
  const path = `${BACKEND_URL}/user/transactions/${transaction_id}/package`;

  const body = JSON.stringify({
    email,
  });

  const data = {
    method: 'POST',
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<ITaskPandadocLink>;
  });
};
