import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "wd-skeleton",
    style: _normalizeStyle({
      width: _ctx.width,
      height: _ctx.height,
    })
  }, null, 4))
}