import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cookie-consent__wrapper" }
const _hoisted_2 = { class: "cookie-consent__text is--body-text-1" }
const _hoisted_3 = { class: "cookie-consent__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade-change" }, {
    default: _withCtx(() => [
      (_ctx.isShowPopup)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "CookieConsent cookie-consent",
            style: _normalizeStyle({ maxWidth: `${_ctx.maxWidthContainer}px` })
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.message), 1),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_BaseButton, {
                  secondary: "",
                  small: "",
                  class: "cookie-consent__button",
                  href: _ctx.linkHref
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.linkText), 1)
                  ]),
                  _: 1
                }, 8, ["href"]),
                _createVNode(_component_BaseButton, {
                  small: "",
                  class: "cookie-consent__button",
                  onClick: _ctx.dismissCookie
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.dismissButtonText), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ], 4))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}