import { ref, computed, watch } from 'vue';
import { INotification } from '@/types/api/notifications';

import { fetchGetNotificationsAll, useCore } from '@/store';

import { generalErrorHandling } from '@/helpers/generalErrorHandling';
import { useChat, useTasks } from '@/composable';
import env from '@/config';
import { notify } from '@kyvg/vue3-notification';


const { NOTIFICATION_URL } = env;
const { socket, selectedTransaction, person } = useCore();
const { setChatData } = useChat();
const { getTasksList } = useTasks();
const { getTaskByIdData } = useTasks();

const isGetNotificationsLoading = ref(false);

const isGetNotificationsError = ref(false);

const getNotificationsData = ref<INotification[]>([]);

const isNotificationSidebarOpen = ref(false);

const notificationLength = computed(() => getNotificationsData.value.length);
const notificationRetry = ref(0);


const ERROR_OPTIONS = {
  text: 'connection to Web Sockets is closed',
  data: {
    status: 3,
  },
  type: 'error',
  group: 'transaction',
  duration: 10000,
};

const SUCCESS_OPTIONS = {
  text: 'connection to Web Sockets is opened',
  type: 'success',
  data: {
    status: 1,
  },
  group: 'transaction',
  duration: 10000,
};

export const useNotifications = () => {
  const getNotificationsAll = async () => {
    isGetNotificationsLoading.value = true;
    const response = await fetchGetNotificationsAll().catch((error: Response) => {
      isGetNotificationsError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      getNotificationsData.value = response;
    }
    isGetNotificationsLoading.value = false;
  };

  const updateNotificationsData = (data: string) => {
    const notification = JSON.parse(data) as INotification;
    getNotificationsData.value.push(notification);
  };

  const notificationSidebarOpen = () => {
    isNotificationSidebarOpen.value = true;
    document.body.classList.add('is-notification-sidebar-open');
  };

  const notificationSidebarClose = () => {
    isNotificationSidebarOpen.value = false;
    document.body.classList.remove('is-notification-sidebar-open');
  };


  return {
    getNotificationsAll,
    updateNotificationsData,
    notificationSidebarOpen,
    notificationSidebarClose,
    notificationLength,
    isNotificationSidebarOpen,
    isGetNotificationsLoading,
    isGetNotificationsError,
    getNotificationsData,
    notificationRetry,
  };
};
