<template>
  <component
    :is="href ? 'a' : to ? 'router-link' : 'button'"
    v-bind="$attrs"
    :to="to"
    :href="href"
    :class="classes"
    :disabled="to ? false : disabled"
    class="BaseButton base-button is--button"
    data-testid="base-button"
    @click="onClick"
  >
    <span class="base-button__inner">
      <img
        v-if="navigationPrev"
        v-svg-inline
        class="base-button__nav-icon"
        :src="require(`@/assets/images/icons/prev.svg`)"
        alt="base-button__nav-icon-prev"
      >
      <img
        v-if="icon"
        v-svg-inline
        class="base-button__icon"
        :src="require(`@/assets/images/icons/${iconPathName}.svg`)"
        alt="base-button__icon"
      >
      <slot>{{ text }}</slot>
      <img
        v-if="navigationNext"
        v-svg-inline
        class="base-button__nav-icon"
        :src="require(`@/assets/images/icons/next.svg`)"
        alt="base-button__nav-icon-next"
      >
    </span>
    <span
      v-if="loading"
      class="base-button__loader"
    />
  </component>
</template>

<script lang="ts">
import {
  computed, defineComponent, ref, watch,
} from 'vue';

export default defineComponent({
  name: 'BaseButton',
  props: {
    to: [String, Object],
    modelValue: Boolean,
    href: String,
    text: String,
    iconType: {
      type: String,
      default: 'edit',
    }, // set type if you choose icon type

    // condition props
    small: Boolean,
    disabled: Boolean,
    loading: Boolean,
    block: Boolean,

    // type props
    primary: Boolean,
    secondary: Boolean, // can be used for icon type
    tetriary: Boolean,
    ghost: Boolean,
    icon: Boolean,
    navigationPrev: Boolean, // is automatically added small, outlined
    navigationNext: Boolean,
    toggle: Boolean,
    active: Boolean,
  },
  emits: ['click', 'update:modelValue'],
  setup(props, ctx) {
    const toggleActive = ref(props.modelValue);
    const classes = computed(() => ({
      'is--small is--small-text-2': props.small || props.navigationNext || props.navigationPrev,
      'is--disabled': props.disabled,
      'is--loading': props.loading,
      'is--primary': props.primary,
      'is--ghost': props.ghost,
      'is--block': props.block,
      'is--secondary': props.secondary || props.navigationNext || props.navigationPrev,
      'is--tetriary': props.tetriary,
      'is--icon': props.icon,
      'is--navigation-prev': props.navigationPrev,
      'is--navigation-next': props.navigationNext,
      'is--toggle-active': props.toggle && toggleActive.value,
      'is--active': props.active,
    }));
    const iconPathName = computed(() => {
      switch (props.iconType) {
        case 'edit':
          return 'edit';
        case 'email':
          return 'email';
        case 'dollar':
          return 'dollar';
        case 'file':
          return 'file';
        case 'mail':
          return 'mail';
        case 'download':
          return 'download';
        case 'close':
          return 'close';
        default:
          return props.iconType;
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onClick = (event: any) => {
      if (props.toggle) {
        toggleActive.value = !toggleActive.value;
        ctx.emit('update:modelValue', toggleActive.value);
      }
      ctx.emit('click', event);
    };

    watch(() => props.modelValue, (value) => {
      toggleActive.value = value;
    });

    return {
      classes,
      iconPathName,
      onClick,
    };
  },
});
</script>

<style lang="scss">
// stylelint-disable scale-unlimited/declaration-strict-value

.base-button {
  $root: &;

  --base-button__icon-width: 20px;
  --base-button__icon-height: 20px;

  // general type button
  &:not(.is--icon) {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 45px;
    padding: 12px 20px;
    border-radius: 30px;
    color: $grayscale-white;
    text-decoration: none;
    cursor: pointer;
    background: $accent;
    border: none;
    outline: none;
    transition: all ease-out 0.3s;

    &:hover {
      text-decoration: none;
      background: $accent80;
      color: $grayscale-white;
    }

    &:active {
      background: $accent80;
    }

    &.is--active {
      background: $accent80;
    }
  }

  &__inner {
    z-index: 1;
    pointer-events: none;
    display: flex;
    align-items: center;
  }

  &.is--small {
    height: 34px;
    font-size: 12px;
    line-height: 18px;
    padding: 8px 12px;
  }

  &.is--disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.3;
  }

  &.is--toggle-active {
    background: $accentHovered !important;
    color: $grayscale-white !important;
  }

  &.is--block {
    width: 100%;
  }

  &.is--icon {
    display: inline-flex;
    padding: 8px;
    align-items: flex-start;
    border-radius: 30px;
    flex-shrink: 0;
    color: $accent;
    cursor: pointer;
    border: none;
    outline: none;
    transition: all 0.25s;

    &:hover {
      background: $accent20;
    }

    &.is--disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.3;
      background: $grayscale10;
    }

    &.is--secondary {
      border: 1px solid $accent;

      &:hover {
        background: $accent;
        color: $grayscale-white;
      }
    }
  }

  &__icon {
    display: flex;
    width: var(--base-button__icon-width);
    height: var(--base-button__icon-height);
    justify-content: center;
    align-items: center;
  }

  &.is--navigation-prev,
  &.is--navigation-next {
    padding: 8px 12px;

    &:hover {
      #{$root}__nav-icon {
        color: $grayscale-white;
      }
    }
  }

  &__nav-icon {
    color: $accent;
    flex-shrink: 0;
    height: 10px;
    transition: all 0.25s;
  }

  &.is--navigation-prev {
    #{$root}__nav-icon {
      margin-right: 10px;
      margin-left: 3px;
    }
  }

  &.is--navigation-next {
    #{$root}__nav-icon {
      margin-left: 6px;
      margin-right: -8px;
    }
  }

  // type loading
  &.is--loading {
    pointer-events: none;

    #{$root}__inner {
      opacity: .4;
    }

    #{$root}__loader {
      position: absolute;
      z-index: 3;
      display: block;
      width: 25px;
      height: 25px;
      content: "";
      border: 3px solid #6095ff;
      border-width: 3px;
      border-top-color: white;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }
  }

  &:not(.is--icon).is--secondary {
    color: $accent;
    background: $grayscale-white;
    border: 1px solid $accent;

    &:hover {
      color: $grayscale-white;
      background: $accent;
    }

    &:active {
      color: $grayscale-white;
      background: $accent80;
    }

    &.is--active {
      color: $grayscale-white;
      background: $accent80;
    }
  }

  &:not(.is--icon).is--tetriary {
    color: $accent;
    background: $accent20;

    &:hover {
      background: $accent40;
    }

    &:active {
      background: $accent60;
    }

    &.is--active {
      background: $accent60;
    }
  }

  &:not(.is--icon).is--ghost {
    color: $accent;
    background: transparent;

    &:hover {
      background: $accent20;
    }

    &:active {
      background: $accent60;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
