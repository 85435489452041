import { shallowReactive } from 'vue';

export class Socket {
  private static instance: Socket;

  private ws!: WebSocket;

  public isOpened = false;

  constructor() {
    // do not set subscribes because missing reactivity,
    // use for that initialize method
  }

  public static buildClass() {
    if (Socket.instance) return Socket.instance;

    const instance = new Socket();

    Socket.instance = shallowReactive(instance);

    return Socket.instance;
  }

  public connect(url: string) {
    const { protocol } = window.location;
    let uri = '';

    if (protocol === 'https:') {
      uri = url.replace('https', 'wss');
    } else if (protocol === 'http:') {
      uri = url.replace('https', 'ws');
    }

    this.ws = new WebSocket(uri);

    this.ws.onopen = () => {
      this.isOpened = true;
      // eslint-disable-next-line no-console
      console.log(`connection to ${uri} is opened`);
    };

    this.ws.onclose = () => {
      this.isOpened = false;
      // eslint-disable-next-line no-console
      console.log(`connection to ${uri} is closed`);
    };
  }

  public onMessage(callback: (msg: MessageEvent) => void) {
    this.ws.onmessage = callback;
  }

  public disconnect() {
    if (this.ws) {
      this.ws.close();
    }
  }
}
