<template>
  <HeaderCard class="HeaderProfile header-profile">
    <WdSkeleton
      v-if="isLoading"
      height="33px"
      width="142px"
      class="header-profile__skeleton"
    />
    <BaseDropdown
      v-else
      light
      dropdown-right
      no-scrollbar
      :min-items-width="180"
      class="header-profile__dropdown"
    >
      <template #selected>
        <DefaultAvatar
          class="header-profile__icon-wrap"
          :user-letter="userLetter"
        />
        <span
          class="header-profile__value is--body-text-1"
          v-text="userName"
        />
      </template>

      <template #listItem>
        <a
          :href="`${env.FRONTEND_APP_URL}/profile/settings`"
          class="header-profile__profile is--body-text-1"
          v-text="'Profile Settings'"
        />
        <a
          :href="`${env.FRONTEND_APP_URL}/settings`"
          class="header-profile__profile is--body-text-1"
          v-text="'Change Password'"
        />
        <a
          v-if="isSubscription"
          :href="`${env.FRONTEND_APP_URL}/pricing`"
          class="header-profile__profile is--body-text-1"
          v-text="'Subscription'"
        />
        <div
          class="header-profile__profile is--body-text-1"
          data-testid="logout-btn"
          @click="logoutHandler"
          v-text="'Log Out'"
        />
      </template>
    </BaseDropdown>
  </HeaderCard>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useCore } from '@/store';
import env from '@/config';
import BaseDropdown from '@/components/ui/BaseDropdown.vue';
import HeaderCard from './HeaderCard.vue';
import DefaultAvatar from '@/components/common/DefaultAvatar.vue';
import WdSkeleton from '@/components/ui/WdSkeleton.vue';
import { useUsers, useLogout } from '@/composable';
import { useRouter } from 'vue-router';
import { gtagClick } from '@/helpers/gtag';

export default defineComponent({
  name: 'HeaderProfile',
  components: {
    BaseDropdown,
    HeaderCard,
    DefaultAvatar,
    WdSkeleton,
  },
  setup() {
    const { person } = useCore();
    const { isGetUserInfoLoading } = useUsers();
    const { onLogout } = useLogout();
    const router = useRouter();

    const userName = computed(() => person.value.User?.first_name);
    const userLetter = computed(() => Array.from(userName.value || '')[0]);
    const isLoading = computed(() => isGetUserInfoLoading.value);
    const isSubscription = computed(() => person.value.isPayedPlanUser);

    const logoutHandler = async () => {
      gtagClick('Landing Header Logout desktop');
      await onLogout();
    };

    const getActive = (name: string) => {
      if (router.currentRoute.value.name === name) {
        return 'is--active';
      }
      return '';
    };

    return {
      userName,
      userLetter,
      kratosUrl: env.KRATOS_URL,
      env,
      logoutHandler,
      isLoading,
      isSubscription,
      getActive,
    };
  },
});
</script>

<style lang="scss">
.header-profile {
  $root: &;

  min-width: 133px;
  padding: 0 10px;

  &__icon {
    color: $accent;
  }

  &__icon-wrap {
    margin-right: 12px;
  }

  &__value {
    margin-right: 26px;
  }

  &__dropdown {
    width: 100%;
  }

  &__profile {
    padding: 11px 12px;
    display: block;
    color: $grayscale90;

    &.is--active {
      color: $accent;
    }
  }
}
</style>
