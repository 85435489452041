// eslint-disable-next-line object-curly-newline
import { Ref, ref } from 'vue';

import { Person } from '@/classes/Person';
import { Socket } from '@/classes/Socket';
import { Transaction } from '@/classes/Transaction';

const person = ref(Person.buildClass()) as Ref<Person>;
const socket = ref(Socket.buildClass()) as Ref<Socket>;
const selectedTransaction = ref(Transaction.buildClass()) as Ref<Transaction>;

export const useCore = () => ({
  person,
  socket,
  selectedTransaction,
});
