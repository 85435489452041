import {
  IAuthGenericError, IAuthError422, IAuthError400, IRecovery, ISettingsError400,
} from '@/types/api/auth';

import { notify } from '@kyvg/vue3-notification';

const NOTIFY_OPTIONS = {
  text: 'Something went wrong',
  data: {
    description: 'Please try again',
    status: 3,
  },
  type: 'error',
  group: 'transaction',
  duration: 10000,
};

export const generalErrorHandling = async (error: Response) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const errorJson = await error.json();
    if (errorJson as IAuthGenericError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data:IAuthGenericError = errorJson;
      if (data.error && data.error.message) NOTIFY_OPTIONS.text = data.error.message;
    }
  } catch (errorTry) {
    // eslint-disable-next-line no-console
    console.log(errorTry);
  }
  notify(NOTIFY_OPTIONS);
};

export const errorHandling422 = async (error: Response) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const errorJson = await error.json();

    if (errorJson as IAuthGenericError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data:IAuthGenericError = errorJson;
      if (data.error && data.error.message) NOTIFY_OPTIONS.text = data.error.message;
    }

    if (errorJson as IAuthError422) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data:IAuthError422 = errorJson;
      if (data.message) NOTIFY_OPTIONS.text = data.message;
    }

    if (errorJson as IAuthError400) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data:IAuthError400 = errorJson;
      if (data.ui && data.ui.messages && data.ui.messages[0].text) {
        NOTIFY_OPTIONS.text = data.ui.messages[0].text;
      }
      if (data.ui && data.ui.nodes) {
        data.ui.nodes.map((node) => {
          if (node.messages[0] && node.messages[0].text) {
            if (node.messages[0].text === 'The password can not be used because the password has been found in data breaches and must no longer be used.') {
              NOTIFY_OPTIONS.text = 'Your password should contain at least 8 symbols: one Capital letter one lowercase, number, and special symbol';
            } else NOTIFY_OPTIONS.text = node.messages[0].text;
          }
          return NOTIFY_OPTIONS;
        });
      }
    }
  } catch (errorTry) {
    // eslint-disable-next-line no-console
    console.log(errorTry);
  }
  notify(NOTIFY_OPTIONS);
};

export const errorHandlingRecovery = async (error: Response) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const errorJson = await error.json();

    if (errorJson as IAuthGenericError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data:IAuthGenericError = errorJson;
      if (data.error && data.error.message) NOTIFY_OPTIONS.text = data.error.message;
    }

    if (errorJson as IRecovery) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data:IRecovery = errorJson;
      if (data.ui && data.ui.messages && data.ui.messages[0].text) NOTIFY_OPTIONS.text = data.ui.messages[0].text;
      if (data.ui && data.ui.nodes) {
        data.ui.nodes.map((node) => {
          if (node.messages[0] && node.messages[0].text) {
            NOTIFY_OPTIONS.text = node.messages[0].text;
          }
          return NOTIFY_OPTIONS;
        });
      }
    }
  } catch (errorTry) {
    // eslint-disable-next-line no-console
    console.log(errorTry);
  }
  notify(NOTIFY_OPTIONS);
};


export const errorHandlingSettings = async (error: Response) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const errorJson = await error.json();

    if (errorJson as IAuthGenericError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data:IAuthGenericError = errorJson;
      if (data.error && data.error.message) NOTIFY_OPTIONS.text = data.error.reason || data.error.message;
    }

    if (errorJson as IAuthError422) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data:IAuthError422 = errorJson;
      if (data.message) NOTIFY_OPTIONS.text = data.message;
    }

    if (errorJson as ISettingsError400) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data:IAuthError400 = errorJson;
      if (data.ui && data.ui.messages && data.ui.messages[0].text) NOTIFY_OPTIONS.text = data.ui.messages[0].text;
      if (data.ui && data.ui.nodes) {
        data.ui.nodes.map((node) => {
          if (node.messages[0] && node.messages[0].text) {
            NOTIFY_OPTIONS.text = node.messages[0].text;
          }
          return NOTIFY_OPTIONS;
        });
      }
    }
  } catch (errorTry) {
    // eslint-disable-next-line no-console
    console.log(errorTry);
  }
  notify(NOTIFY_OPTIONS);
};
