import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "base-dropdown__items" }
const _hoisted_3 = { class: "base-dropdown__elements" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_svg_inline = _resolveDirective("svg-inline")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["BaseDropdown base-dropdown", {
      'is--light': _ctx.light,
      'is--dropdown-center': _ctx.dropdownCenter,
      'is--dropdown-right': _ctx.dropdownRight,
      'is--no-scrollbar': _ctx.noScrollbar,
      'is--disabled': _ctx.disabled,
    }]),
    style: _normalizeStyle({
      '--minItemsWidth': _ctx.minItemsWidth + 'px',
    })
  }, [
    (_ctx.notificationLength)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "base-dropdown__notification-count",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.notificationSidebarOpen && _ctx.notificationSidebarOpen(...args)))
        }, _toDisplayString(_ctx.notificationLength), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["base-dropdown__selected is--button", { 'is--active': _ctx.isActive }]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (!_ctx.disabled ? _ctx.isActive = !_ctx.isActive : null))
    }, [
      _renderSlot(_ctx.$slots, "selected"),
      (!_ctx.noArrow)
        ? _withDirectives((_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.arrowDownIcon,
            class: "base-dropdown__selected-arrow",
            alt: "dropdown arrow down"
          }, null, 8, _hoisted_1)), [
            [_directive_svg_inline]
          ])
        : _createCommentVNode("", true)
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "listItem")
      ])
    ], 512), [
      [_vShow, _ctx.isActive]
    ])
  ], 6))
}