import '@/assets/scss/main.scss';

import { createApp } from 'vue';
import { initPlugins } from '@/plugins';
import { createRouter } from '@/router';
import { createPinia } from 'pinia';

import App from './App.vue';

const router = createRouter();

const app = createApp(App);
const pinia = createPinia();

// need for devtools in stands
app.config.performance = process.env.VUE_APP_ENV !== 'master';

app
  .use(initPlugins, { router })
  .use(router)
  .use(pinia)
  .mount('#app');
