<template>
  <!-- eslint-disable vue/no-multiple-template-root -->

  <div
    class="header-menu-bg"
    :class="{ 'is-active': modelValue }"
    @click="$emit('update:modelValue', false)"
  />

  <div
    class="header-burger"
    :class="{ 'is-close': modelValue }"
    @click="$emit('update:modelValue', !modelValue)"
  >
    <div v-if="!modelValue" class="header-burger__btn">
      <img
        v-svg-inline
        :src="burgerIcon"
        class="header-burger__icon"
        alt="mobile burger"
      >
    </div>
    <span v-if="modelValue">
      <img
        v-svg-inline
        :src="require('@/assets/images/icons/close.svg')"
        class="header-burger__icon is-close"
        alt="mobile close"
      >
    </span>
  </div>


  <div
    class="MobileMenu mobile-menu"
    :class="{ 'is-active': modelValue, 'is--logged-in': isLoggedIn }"
  >
    <div class="mobile-menu__header-shadow" />

    <div class="mobile-menu__list">
      <div class="mobile-menu__menu">
        <div
          v-if="isLoggedIn"
          class="mobile-menu__profile"
        >
          <DefaultAvatar
            class="mobile-menu__profile-wrap"
            :user-letter="userLetter"
          />
          <span
            class="mobile-menu__profile-value is--body-text-1"
            v-text="userName"
          />
        </div>
        <a
          v-if="isLoggedIn"
          :href="`${env.FRONTEND_APP_URL}/profile/settings`"
          class="mobile-menu__item  is--body-text-1 is--padding"
          v-text="'Profile Settings'"
        />
        <a
          v-if="isLoggedIn"
          :href="`${env.FRONTEND_APP_URL}/settings`"
          class="mobile-menu__item  is--body-text-1 is--padding"
          v-text="'Change Password'"
        />
        <ul class="mobile-menu__nav">
          <li
            v-for="menuItem in MENU_HEADER"
            :key="menuItem.text"
            class="mobile-menu__item is--body-text-1"
            :class="getActive(menuItem.name)"
          >
            <router-link
              v-if="menuItem.name"
              :to="{ name: menuItem.name }"
              class="mobile-menu__item-link is--body-text-1"
              v-text="menuItem.text "
            />
            <a
              v-if="menuItem.href"
              :href="menuItem.href"
              target="_blank"
              class="mobile-menu__item-link is--body-text-1"
              v-text="menuItem.text "
            />
          </li>
        </ul>
      </div>
      <div class="mobile-menu__btns">
        <BaseButton
          v-if="isLoggedIn"
            :href="`${env.FRONTEND_APP_URL}/signin`"
          small
          secondary
          class="mobile-menu__auth"
          @click="onLogoutHandler"
          v-text="'Log Out'"
        />
        <BaseButton
          v-if="!isLoggedIn"
            :href="`${env.FRONTEND_APP_URL}/`"
          class="mobile-menu__auth"
          small
          @click="signUpHandler"
          v-text="'Sign Up'"
        />
        <BaseButton
          v-if="!isLoggedIn"
          class="mobile-menu__auth"
          small
          secondary
          @click="signInHandler"
          v-text="'Log In'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  watch,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  computed,
  defineAsyncComponent,
} from 'vue';
import { useRouter } from 'vue-router';
import { useCore } from '@/store';
import { blockedBody } from '@/helpers/blocked-body';
import { useBreakpoints, useLogout } from '@/composable';
import {
  ROUTE_HOME,
} from '@/helpers/enums/routes';

import env from '@/config';

import DefaultAvatar from '@/components/common/DefaultAvatar.vue';
import { MENU_HEADER } from '@/config/menu';

import burgerIcon from '@/assets/images/icons/burger-menu.svg';
import { gtagClick } from '@/helpers/gtag';

// Because component in v-if block - make it async
const BaseButton = defineAsyncComponent(() => import(
  /* webpackChunkName: "BaseButton" */
  '@/components/ui/BaseButton.vue'
));


const useVhHeight = () => {
  const setHeight = () => {
    const mobileMenuHeight = window.innerHeight * 0.01;
    document.documentElement.style.setProperty(
      '--vh',
      `${mobileMenuHeight}px`,
    );
  };

  onMounted(() => {
    setHeight();
    window.addEventListener('resize', setHeight);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', setHeight);
  });

  return setHeight;
};

const useBlockedBody = (
  props: { modelValue: boolean },
  close: () => void,
) => {
  const { isDesktopMD } = useBreakpoints();

  let unBlockedBody: (() => void) | null = null;

  watch([isDesktopMD], () => {
    close();
  });

  watch(() => props.modelValue, () => {
    unBlockedBody?.();
    unBlockedBody = null;

    if (props.modelValue) {
      unBlockedBody = blockedBody();
    }
  });

  onBeforeUnmount(() => {
    unBlockedBody?.();
    unBlockedBody = null;
  });
};

export default defineComponent({
  name: 'MobileMenu',
  components: {
    BaseButton,
    DefaultAvatar,
  },
  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, ctx) {
    const { person } = useCore();
    const { currentRoute } = useRouter();
    const close = () => ctx.emit('update:modelValue', false);
    const isLoggedIn = computed(() => person.value.loggedIn);

    void useBlockedBody(props, close);
    void useVhHeight();

    const userEmail = computed(() => person.value.User?.email);
    const userId = computed(() => person.value.User?.id);
    const userName = computed(() => person.value.userData?.first_name);
    const userLetter = computed(() => Array.from(userName.value || '')[0]);

    const signInHandler = () => {
      gtagClick('Landing Header Login mobile');
    };

    const signUpHandler = () => {
      gtagClick('Landing Header Signup mobile');
    };

    const { onLogout } = useLogout();

    const onLogoutHandler = async () => {
      gtagClick('Landing Header Logout mobile');
      await onLogout();
    };

    const getActive = (name: string) => {
      if (currentRoute.value.name === name) {
        return 'is--active';
      }
      return '';
    };

    return {
      isLoggedIn,
      userEmail,
      userId,
      userName,
      userLetter,
      signInHandler,
      signUpHandler,
      kratosUrl: env.KRATOS_URL,
      routeProfile: ROUTE_HOME,
      onLogoutHandler,
      MENU_HEADER,
      burgerIcon,
      getActive,
      env,
    };
  },
});
</script>

<style lang="scss">

.header-menu-bg,
.header-burger,
.mobile-menu {
  @include media-gt(desktop-md) {
    display: none !important;
  }
}

.header-menu-bg {
  position: fixed;
  top: $header-height;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-menu-bg;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.33);
  opacity: 0;
  transition: 0.3s;

  &.is-active {
    visibility: visible;
    opacity: 1;
  }
}

.mobile-menu {
  position: fixed;
  top: $header-height;
  right: 0;
  z-index: $z-index-menu;
  display: flex;
  flex-direction: column;
  width: 241px;
  height: calc(100% - $header-height);
  margin-right: 0;
  background: $color-white;
  opacity: 0;
  overflow-y: hidden;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateX(110%);

  &__item {
    padding: 14px 0;
    display: block;
    color: $grayscale90;

    &.is--padding {
      padding-left: 12px;
      padding-right: 12px;
    }

    &.is--active {
      color: $accent;
    }
  }

  &__item-link {
    color: inherit;
  }

  &__header-shadow {
    width: 100%;
    height: 10px;
    position: fixed;
    top: -10px;
    z-index: $z-index-menu;
    box-shadow: $small-shadow;
  }

  &.is-active {
    opacity: 1;
    transform: translateX(0);
  }

  &__list {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    min-height: calc(100% - $header-height);
  }

  &__header-wrapper {
    padding-right: 21px;
    background: #152c76;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    align-items: center;
    padding: 0 12px;
    width: 100%;
    margin-bottom: 48px;
  }

  &__auth {
    width: 100%;
    & + & {
      margin-top: 24px;
    }
  }

  &__divider {
    width: 100%;
    margin-right: 15px;
    border-top: 2px solid #2244a8;
  }

  &__social-links-wrapper {
    padding: 5px 27px 15px 20px;
    margin-top: auto;
    font-size: 12px;
    font-weight: 600;
    color: $color-green-vogue;
    > span {
      color: $color-text-black;
      font-size: 14px;
    }
  }

  &__btn {
    & + & {
      margin-top: 18px;
    }
  }

  &__arrow {
    height: 15px;
  }

  &__login {
    &-wrap {
      padding: 0 31px 15px;
      text-align: center;
    }

    &-icon {
      width: 72px;
      margin: 23px 0 10px;
    }
  }

  &__menu {
    width: 100%;
  }

  &__profile {
    background: $accent20;
    padding: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 13px;
  }

  &__profile-wrap {
    margin-right: 12px;
  }

  &__profile-value{
    color: $grayscale70;
  }

  &.is--logged-in {
    .mobile-menu__nav {
      padding-top: 13px;
      border-top: 1px solid $grayscale30;
      margin-top: 14px;
    }
  }

  &__nav {
    margin: 0 12px;
  }
}

.header-burger {
  z-index: $z-index-menu-burger;
  margin-left: 19px;
  color: $color-text-header;
  cursor: pointer;
  transition: color 0.3s;

  @include media-lt(tablet) {
    margin: 0 0 0 15px;
  }

  &__btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &.is-close {
    color: $accent;
  }

  &__icon {
    color: $accent;
    width: 24px;
    height: auto;
    &.is-close {
      width: 17px;
    }
  }
}
</style>
