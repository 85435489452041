<template>
  <div class="Loader loader" data-testid="loader">
    <div class="loader__circle">
      <div class="loader__wrap">
        <img
          v-svg-inline
          class="loader__logo"
          src="@/assets/images/logo.svg"
          alt="loader logo"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, onBeforeUnmount } from 'vue';
import { blockedBody, unBlockedBody } from '@/helpers/blocked-body';


export default defineComponent({
  name: 'UnLoader',
  setup: () => {
    onMounted(() => blockedBody());
    onBeforeUnmount(() => unBlockedBody());
  },
});
</script>

<style lang="scss">
.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-loader-global;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  &__circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
  }

  &__wrap {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    width: 300px;
  }
}
</style>
