import {
  fetchGetTransactions, fetchSetProperty, fetchSetTransactions, useCore,
  fetchGetTransactionsById, fetchSetMember, fetchGetEstimate,
  fetchGetEmails, fetchSetDocument,
} from '@/store';
import {
  IMember, IProperty, ITransaction, ITransactionCreate, IEstimate, IEmailPartner,
} from '@/types/api/transactions';
import { generalErrorHandling } from '@/helpers/generalErrorHandling';
import { ref, computed } from 'vue';
import { useTasks } from '@/composable/useTasks';
import { MEMBER_STATUS } from '@/config/general';
import { ITaskList } from '@/types/api/tasks';

const { getTaskByIdData, getTasksListData } = useTasks();

const isGetTransactionsLoading = ref(false);
const isSetTransactionsLoading = ref(false);
const isSetPropertyLoading = ref(false);
const isGetTransactionsByIdLoading = ref(false);
const isSetMemberLoading = ref(false);
const isGetEstimateLoading = ref(false);
const isGetEmailsLoading = ref(false);
const isSetDocumentLoading = ref(false);

const isGetTransactionsError = ref(false);
const isSetTransactionsError = ref(false);
const isSetPropertyError = ref(false);
const isGetTransactionsByIdError = ref(false);
const isSetMemberError = ref(false);
const isGetEstimateError = ref(false);
const isGetEmailsError = ref(false);
const isSetDocumentError = ref(false);

const getTransactionsData = ref<ITransaction[]>();
const setTransactionsData = ref();
const setPropertyData = ref();
const getTransactionsByIdData = ref<ITransaction>();
const setMemberData = ref();
const getEstimateData = ref<IEstimate>();
const getEmailsData = ref<IEmailPartner[]>();
const setDocumentData = ref();
const getMLSListData = computed(() => ([
  {
    id: 1,
    name: 'Real estate transfer disclosure',
    mime: 'application/pdf',
    required: true,
    status: 'pending',
    task_id: 96,
    data: getTasksListData.value?.filter((item: ITaskList) => item.task_template.id === 96)[0],
    meta_data: {},
  },
  {
    id: 2,
    name: 'Seller Property Questionnaire',
    mime: 'application/pdf',
    required: false,
    status: 'completed',
    task_id: 110,
    data: getTasksListData.value?.filter((item: ITaskList) => item.task_template.id === 110)[0],
    meta_data: {
      big: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_big.webp',
      small: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_small.webp',
      medium: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_medium.webp',
    },
  },
  {
    id: 3,
    name: 'Order a Property Inspection',
    mime: 'image/png',
    required: false,
    status: 'pending',
    task_id: 93,
    data: getTasksListData.value?.filter((item: ITaskList) => item.task_template.id === 93)[0],
    meta_data: {},
  },
  {
    id: 4,
    name: 'Order a Property Inspection',
    mime: 'image/png',
    required: false,
    status: 'completed',
    task_id: '93',
    data: getTasksListData.value?.filter((item: ITaskList) => item.task_template.id === 93)[0],
    meta_data: {},
  },
  {
    id: 5,
    name: 'Order a Property Inspection Doc',
    mime: 'application/pdf',
    required: true,
    status: 'completed',
    task_id: 93,
    data: getTasksListData.value?.filter((item: ITaskList) => item.task_template.id === 93)[0],
    fileName: 'property_inspection.pdf',
    meta_data: {
      big: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_big.webp',
      small: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_small.webp',
      medium: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_medium.webp',
    },
  },
  {
    id: 6,
    name: 'Order a Property Inspection Image',
    mime: 'image/png',
    required: true,
    status: 'completed',
    task_id: 93,
    data: getTasksListData.value?.filter((item: ITaskList) => item.task_template.id === 93)[0],
    fileName: 'property_inspection.png',
    meta_data: {
      big: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_big.webp',
      small: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_small.webp',
      medium: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_medium.webp',
    },
  },
  {
    id: 7,
    name: 'Create Coming Soon Photos',
    task_id: 119,
    data: getTasksListData.value?.filter((item: ITaskList) => item.task_template.id === 119)[0],
    folder: true,
    status: 'pending',
    required: false,
    files: [
      {
        id: 92,
        mime: 'application/pdf',
        name: 'Lead-Based_Paint_and_Lead-Based_Paint_Hazards_Disclosure.pdf',
        meta_data: {},
      },
      {
        id: 109,
        mime: 'image/png',
        name: 'Fill the document.png',
        meta_data: {
          big: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_big.webp',
          small: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_small.webp',
          medium: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_medium.webp',
        },
      },
      {
        id: 104,
        mime: 'application/pdf',
        name: 'Parking_and_Storage_Disclosure.pdf',
        meta_data: {},
      },
    ],
  },

  {
    id: 7,
    name: 'Create Coming Soon Photos',
    task_id: 119,
    data: getTasksListData.value?.filter((item: ITaskList) => item.task_template.id === 119)[0],
    folder: true,
    status: 'completed',
    required: false,
    files: [
      {
        id: 92,
        mime: 'application/pdf',
        name: 'Lead-Based_Paint_and_Lead-Based_Paint_Hazards_Disclosure.pdf',
        meta_data: {},
      },
      {
        id: 109,
        mime: 'image/png',
        name: 'Fill the document.png',
        meta_data: {
          big: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_big.webp',
          small: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_small.webp',
          medium: 'https://storage.googleapis.com/yoursunday-media-thumbs/filer/19025e01e315ea6e740dff3fc37c64aa8c69cf18_medium.webp',
        },
      },
      {
        id: 104,
        mime: 'application/pdf',
        name: 'Parking_and_Storage_Disclosure.pdf',
        meta_data: {},
      },
    ],
  },
]));

export const useTransactions = () => {
  const { selectedTransaction } = useCore();
  const getTransactions = async () => {
    isGetTransactionsLoading.value = true;
    isGetTransactionsError.value = false;
    const response = await fetchGetTransactions().catch((error: Response) => {
      isGetTransactionsError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      getTransactionsData.value = response.data;
      if (response.count > 0) {
        selectedTransaction.value.updateSelectedTransactionData(getTransactionsData.value[0]);
      }
    }

    isGetTransactionsLoading.value = false;
  };

  const setTransactions = async (data: ITransactionCreate) => {
    isSetTransactionsLoading.value = true;
    isSetTransactionsError.value = false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await fetchSetTransactions(data).catch((error: Response) => {
      isSetTransactionsError.value = true;
      void generalErrorHandling(error);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (response) setTransactionsData.value = response;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    isSetTransactionsLoading.value = false;
  };

  const setProperty = async (id: number, data: IProperty) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    isSetPropertyLoading.value = true;
    isSetPropertyError.value = false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await fetchSetProperty(id, data).catch((error: Response) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      isSetPropertyError.value = true;
      void generalErrorHandling(error);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (response) setPropertyData.value = response;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    isSetPropertyLoading.value = false;
  };

  const getTransactionById = async (id: number) => {
    isGetTransactionsByIdLoading.value = true;
    isGetTransactionsByIdError.value = false;
    const response = await fetchGetTransactionsById(id).catch((error: Response) => {
      isGetTransactionsByIdError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      getTransactionsByIdData.value = response;
      selectedTransaction.value.updateSelectedTransactionByIdData(getTransactionsByIdData.value);
    }

    isGetTransactionsByIdLoading.value = false;
  };

  const setMember = async (id: number, data: IMember) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    isSetMemberLoading.value = true;
    isSetMemberError.value = false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await fetchSetMember(id, data).catch((error: Response) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      isSetMemberError.value = true;
      void generalErrorHandling(error);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (response) setMemberData.value = response;
    // update data
    getTransactionsByIdData.value?.members.forEach((item) => {
      if (item.role === data.role) {
        Object.assign(item, data);
        item.status = MEMBER_STATUS.INVITED;
      }
    });
    getTaskByIdData.value?.members.forEach((item) => {
      if (item.role === data.role) {
        Object.assign(item, data);
        item.status = MEMBER_STATUS.INVITED;
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    isSetMemberLoading.value = false;
  };

  const getEstimate = async (id: number) => {
    isGetEstimateLoading.value = true;
    isGetEstimateError.value = false;
    const response = await fetchGetEstimate(id).catch((error: Response) => {
      isGetEstimateError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      getEstimateData.value = response;
    }

    isGetEstimateLoading.value = false;
  };

  const getEmails = async (id: number) => {
    isGetEmailsLoading.value = true;
    isGetEmailsError.value = false;
    const response = await fetchGetEmails(id).catch((error: Response) => {
      isGetEmailsError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      getEmailsData.value = response;
    }

    isGetEmailsLoading.value = false;
  };

  const setDocument = async (transaction_id: number, task_id: number, data: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    isSetDocumentLoading.value = true;
    isSetDocumentError.value = false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await fetchSetDocument(transaction_id, task_id, data).catch((error: Response) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      isSetDocumentError.value = true;
      void generalErrorHandling(error);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (response) setDocumentData.value = response;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    isSetDocumentLoading.value = false;
  };

  const resetTransactionsAll = () => {
    getTransactionsData.value = undefined;
    setTransactionsData.value = undefined;
    setPropertyData.value = undefined;
    getTransactionsByIdData.value = undefined;
    setMemberData.value = ref();
    getEstimateData.value = undefined;
    getEmailsData.value = undefined;
    setDocumentData.value = undefined;
  };

  return {
    getTransactions,
    setTransactions,
    setProperty,
    getTransactionById,
    setMember,
    getEstimate,
    resetTransactionsAll,
    getEmails,
    setDocument,

    isGetTransactionsLoading,
    isSetTransactionsLoading,
    isSetPropertyLoading,
    isGetTransactionsByIdLoading,
    isSetMemberLoading,
    isGetEstimateLoading,
    isGetEmailsLoading,
    isSetDocumentLoading,

    isGetTransactionsError,
    isSetTransactionsError,
    isSetPropertyError,
    isGetTransactionsByIdError,
    isSetMemberError,
    isGetEstimateError,
    isGetEmailsError,
    isSetDocumentError,

    getTransactionsData,
    setTransactionsData,
    setPropertyData,
    getTransactionsByIdData,
    setMemberData,
    getEstimateData,
    getEmailsData,
    getMLSListData,
    setDocumentData,
  };
};
