/* eslint no-underscore-dangle: 0 */

import { shallowReactive } from 'vue';
import env from '@/config';

import { ITransaction } from '@/types/api/transactions';

import { useTasks, useTransactions } from '@/composable';
import { storage } from '@/services';

export class Transaction {
  private static instance: Transaction;

  public transactionId = storage.selectedTransactionId.get() ?? env.DEFAULT_TRANSACTION;

  public transactionData: ITransaction | null = null;

  constructor() {
    // do not set subscribes because missing reactivity,
    // use for that initialize method
  }

  public static buildClass() {
    if (Transaction.instance) return Transaction.instance;

    const instance = new Transaction();

    Transaction.instance = shallowReactive(instance);

    return Transaction.instance;
  }

  // eslint-disable-next-line class-methods-use-this
  public updateSelectedTransactionData(data: ITransaction) {
    // void this.updateTransactionData(data.id);
    this.transactionData = data;
    if (this.transactionId === env.DEFAULT_TRANSACTION) this.updateSelectedTransactionId(data.id);
  }

  public updateSelectedTransactionByIdData(data: ITransaction) {
    this.transactionData = data;
    this.updateSelectedTransactionId(data.id);
  }

  public updateSelectedTransactionId(id: number) {
    this.transactionId = id;
    storage.selectedTransactionId.set(id);
  }

  public async updateTransactionData(id: number = this.transactionId) {
    const { getTransactionById, getEstimate, getEmails } = useTransactions();
    const { getTaskNext, getTasksList, getTagsList } = useTasks();
    await getTransactionById(id);
    void getTasksList(id);
    void getTagsList(id);
    if (!this.isNeedToCreateProperty && !this.isSoldTransaction) void getTaskNext(id);
    if (!this.isNeedToCreateProperty) void getEstimate(id);
    if (!this.isNeedToCreateProperty) void getEmails(id);
  }

  public resetAll() {
    this.transactionData = null;
    this.transactionId = env.DEFAULT_TRANSACTION;
    storage.selectedTransactionId.set(env.DEFAULT_TRANSACTION);
  }

  public get property() {
    return this.transactionData?.property[0];
  }

  public get isCountyEmpty() {
    return this.transactionData?.property[0].county === '';
  }

  public get isNeedToCreateProperty() {
    const { isGetTransactionsLoading, getTransactionsData } = useTransactions();
    return (!this.transactionData?.property[0] && (getTransactionsData.value?.length === 0)
      && !isGetTransactionsLoading.value);
  }

  public get isSoldTransaction() {
    return this.transactionData?.progress_pct === 100;
  }
}
