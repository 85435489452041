import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import { useAuth, useGeneral } from '@/composable';
import { useCore, useGlobalLoader } from '@/store';
import { gtagFlowStart, gtagFlowComplete } from '@/helpers/gtag';

const isLoadingLogout = ref(false);

export const useLogout = () => {
  const router = useRouter();
  const { currentRoute } = router;
  const { person, selectedTransaction } = useCore();
  const { resetAll } = useGeneral();
  const token = ref('');
  const {
    getLogout, getLogoutResponse, getLogoutUrl, getLogoutURLData,
  } = useAuth();

  const isNeedRedirect = computed(() => (
    !currentRoute.value.meta.auth
    // && currentRoute.value.name !== ROUTE_TASK
  ));

  const onLogout = async () => {
    isLoadingLogout.value = true;
    gtagFlowStart('Start Logout Flow');
    await getLogoutUrl();

    if (getLogoutURLData.value) {
      token.value = getLogoutURLData.value.logout_token
        ? getLogoutURLData.value.logout_token
        : getLogoutURLData.value.logout_url.split('token=')[1].toString();
    }

    await getLogout(token.value);

    // eslint-disable-next-line max-len
    if (getLogoutResponse.value && (getLogoutResponse.value.status >= 200) && (getLogoutResponse.value.status <= 300)) {
      gtagFlowComplete('Complete Logout Flow');
      useGlobalLoader().show();
      resetAll();
      selectedTransaction.value.resetAll();
      person.value.resetAll();
      useGlobalLoader().hide();
    }
    isLoadingLogout.value = false;
  };

  return {
    onLogout,
    isLoadingLogout,
  };
};
