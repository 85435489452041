import { ref } from 'vue';
import { fetchCheckout } from '@/store';
import { generalErrorHandling } from '@/helpers/generalErrorHandling';
import { ICheckout } from '@/types/api/payments';
import { storage } from '@/services';

const isGetCheckoutLoading = ref(false);
const isGetPricingUrlsLoading = ref(false);

const isGetCheckoutError = ref(false);

const getCheckoutData = ref<ICheckout>();

const basicPlanUrl = ref('');
const businessPlanUrl = ref('');

export const usePayments = () => {
  const isAuth = storage.loggedIn.get();
  const getCheckout = async (plan: string, period?: string) => {
    isGetCheckoutLoading.value = true;
    isGetCheckoutError.value = false;
    const response = await fetchCheckout(plan, period).catch((error: Response) => {
      isGetCheckoutError.value = true;
      void generalErrorHandling(error);
    });
    if (response) {
      getCheckoutData.value = response;
    }
    isGetCheckoutLoading.value = false;
  };

  const getPricingUrls = async () => {
    if (isAuth) {
      isGetPricingUrlsLoading.value = true;
      await getCheckout('personal');
      if (getCheckoutData.value) {
        basicPlanUrl.value = getCheckoutData.value?.url;
      }
      // await getCheckout('business');
      // if (getCheckoutData.value) {
      //   businessPlanUrl.value = getCheckoutData.value?.url;
      // }
      isGetPricingUrlsLoading.value = false;
    }
  };

  return {
    getCheckout,
    getPricingUrls,

    isGetCheckoutLoading,
    isGetPricingUrlsLoading,

    isGetCheckoutError,

    getCheckoutData,

    basicPlanUrl,
    businessPlanUrl,
  };
};
