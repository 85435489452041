<template>
  <div class="DefaultAvatar default-avatar">
    <img
      v-if="imageUrl"
      class="default-avatar__image"
      :src="imageUrl"
      alt="avatar"
    >
    <span
      v-else
      class="default-avatar__icon is--body-text-1"
      v-text="userLetter"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DefaultAvatar',
  props: {
    userLetter: String,
    imageUrl: String,
  },
});
</script>

<style lang="scss">
.default-avatar {
  position: relative;
  display: flex;
  width: 34px;
  height: 34px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  overflow: hidden;
  background: rgba(86, 63, 200, 0.20);

  &__icon {
    color: $accent;
    text-transform: uppercase;
  }

  &__image {
    width: 100%;
  }
}
</style>
