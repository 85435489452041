import { RouteRecordRaw } from 'vue-router';
import { ROUTE_HOW_IT_WORKS } from '@/helpers/enums/routes';
import { usePageSeo } from '@/composable';

const { setMetaData } = usePageSeo();

const createRoutes = (): RouteRecordRaw[] => [
  {
    path: '/how-it-works',
    name: ROUTE_HOW_IT_WORKS,
    component: () => import(/* webpackChunkName: 'ViewHowItWorks' */ './ViewHowItWorks.vue'),
    beforeEnter() {
      setMetaData({
        seo_title: 'How It Works',
        seo_description: 'Simplify your home-selling journey with Sunday.Pro. We\'ve got all the tools you need to make your transaction seamless. From digitally completing documents to scheduling inspections and more, trust us to guide you from start to finish. Sell your home confidently and efficiently with Sunday.Pro.',
      });
    },
    meta: {
      auth: false,
    },
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createModule = (options: unknown) => {
  const routes = createRoutes();
  return routes;
};

export default createModule;
