import { Router, RouteRecordRaw } from 'vue-router';

import HomeRoutes from '@/views/Home/routes';
import ErrorsRoutes from '@/views/Errors/routes';
import HowItWorksRoutes from '@/views/HowItWorks/routes';

type ICreateRoutesOptions = { router: Router }

export const createRoutes = (options: ICreateRoutesOptions): RouteRecordRaw[] => [
  ...HomeRoutes(options),
  ...HowItWorksRoutes(options),
  // must be last, because in error - `*` route
  ...ErrorsRoutes(options),
];
