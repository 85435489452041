import { RouteRecordRaw } from 'vue-router';
import { ROUTE_HOME } from '@/helpers/enums/routes';
import { usePageSeo } from '@/composable';

const { setMetaData } = usePageSeo();

const createRoutes = (): RouteRecordRaw[] => [
  {
    path: '/',
    name: ROUTE_HOME,
    component: () => import(/* webpackChunkName: 'ViewHome' */ './ViewHome.vue'),
    beforeEnter() {
      setMetaData({
        seo_title: 'Welcome to Sunday Lending',
        seo_description: 'Unlock the secrets to selling your home like a pro with Sunday.Pro. Easily fill documents, complete tasks, and stay organized throughout your home-selling journey. Join satisfied customers like Jim, Kathleen, Matt, and Lori who saved thousands. Get started today',
      });
    },
    meta: {
      auth: false,
    },
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createModule = (options: unknown) => {
  const routes = createRoutes();
  return routes;
};

export default createModule;
