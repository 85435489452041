import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_WdAppLayoutDefault = _resolveComponent("WdAppLayoutDefault")!
  const _component_Notification = _resolveComponent("Notification")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_CookieConsent = _resolveComponent("CookieConsent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "transition-fade-out" }, {
      default: _withCtx(() => [
        (_ctx.globalLoader.isLoading)
          ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_WdAppLayoutDefault),
    _createVNode(_component_notifications, {
      position: "top right",
      group: "transaction"
    }, {
      body: _withCtx((props) => [
        _createVNode(_component_Notification, _mergeProps(props.item, {
          duration: props.item.data.duration,
          onClose: props.close
        }), null, 16, ["duration", "onClose"])
      ]),
      _: 1
    }),
    _createVNode(_component_CookieConsent, {
      domain: _ctx.env.FRONTEND_URL,
      "link-href": "/cookie-policy"
    }, null, 8, ["domain"])
  ], 64))
}