import {
  ROLE_BUYER, ROLE_BUYER_AGENT, ROLE_SELLER, ROLE_SELLER_AGENT,
} from '@/helpers/enums';

export const roleOptions = [
  {
    text: 'I\'m selling my property',
    value: ROLE_SELLER,
  },
  {
    text: 'I\'m buying a property',
    value: ROLE_BUYER,
  },
  {
    text: 'I\'m a seller`s Agent/Broker',
    value: ROLE_SELLER_AGENT,
  },
  {
    text: 'I\'m a buyer`s Agent/Broker',
    value: ROLE_BUYER_AGENT,
  },
];

export enum MEMBER_STATUS {
  CONFIRMED = 'confirmed',
  EMPTY = 'empty',
  INVITED = 'invited',
}

export const TAG_TEXT = [
  {
    text: 'FILL DOCUMENTS',
    value: 'document',
    color: '#FFA378',
    bg_color: 'rgba(255, 163, 120, 0.05)',
    icon: '📝',
  },
  {
    text: 'SCHEDULE INSPECTIONS',
    value: 'inspection',
    color: '#3C8F65',
    bg_color: 'rgba(60, 143, 101, 0.05)',
    icon: '🏠',
  },
  {
    text: 'GET YOUR HOME READY ',
    value: 'home ready',
    color: '#4169E1',
    bg_color: 'rgba(65, 105, 225, 0.05)',
    icon: '🛋️',
  },
  {
    text: 'LET’S GET STARTED ',
    value: 'start',
    color: '#FF00BF',
    icon: '🚀',
  },
];
