import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "header__logo-menu-wrap" }
const _hoisted_2 = { class: "header__nav" }
const _hoisted_3 = ["href", "textContent"]
const _hoisted_4 = { class: "header__right" }
const _hoisted_5 = { class: "header-nav__right" }
const _hoisted_6 = { class: "header-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_HeaderProfile = _resolveComponent("HeaderProfile")!
  const _component_MobileMenu = _resolveComponent("MobileMenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["Header header is--container", { 'is--open-mobile-menu': _ctx.isMobileMenuOpen }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Logo, { class: "header-logo" })
    ]),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.MENU_HEADER, (menuItem) => {
        return (_openBlock(), _createElementBlock("li", {
          key: menuItem.text,
          class: _normalizeClass(["header-nav__item", _ctx.getActive(menuItem.name)])
        }, [
          (menuItem.name)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: menuItem.name },
                class: "header-nav__item-link is--body-text-1",
                textContent: _toDisplayString(menuItem.text)
              }, null, 8, ["to", "textContent"]))
            : _createCommentVNode("", true),
          (menuItem.href)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: menuItem.href,
                target: "_blank",
                class: "header-nav__item-link is--body-text-1",
                textContent: _toDisplayString(menuItem.text )
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (!_ctx.isLoggedIn)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 0,
                href: `${_ctx.env.FRONTEND_APP_URL}/signin`,
                class: "header-btns__sign-in",
                secondary: "",
                small: "",
                "data-testid": "base-button-login",
                onClick: _ctx.signInHandler,
                textContent: 'Log In'
              }, null, 8, ["href", "onClick"]))
            : _createCommentVNode("", true),
          (!_ctx.isLoggedIn)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 1,
                href: `${_ctx.env.FRONTEND_APP_URL}/`,
                class: "header-btns__sign-up",
                small: "",
                onClick: _ctx.signUpHandler,
                textContent: 'Sign Up'
              }, null, 8, ["href", "onClick"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.isLoggedIn)
          ? (_openBlock(), _createBlock(_component_HeaderProfile, {
              key: 0,
              class: "header-btns__profile"
            }))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_MobileMenu, {
        modelValue: _ctx.isMobileMenuOpen,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isMobileMenuOpen) = $event))
      }, null, 8, ["modelValue"])
    ])
  ], 2))
}