import { notify } from '@kyvg/vue3-notification';

const NOTIFY_OPTIONS = {
  text: 'Something went wrong',
  data: {
    description: 'Please try again',
    status: 3,
  },
  type: 'error',
  group: 'transaction',
  duration: 10000,
};

// eslint-disable @typescript-eslint/no-unsafe-assignment

export const generalErrorHandling = async (error: Response) => {
  if (!(error instanceof TypeError)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const errorJson = await error.json();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    if (errorJson && errorJson.error) NOTIFY_OPTIONS.text = errorJson.error;
  }
  notify(NOTIFY_OPTIONS);
};
