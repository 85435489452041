import {
  IProperty, ITransaction, ITransactionCreate,
  IMember, IEstimate, IEmailPartner,
} from '@/types/api/transactions';
import env from '@/config';

const { BACKEND_URL } = env;

export const fetchGetTransactions = () => {
  const path = `${BACKEND_URL}/user/transactions`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<{ data: ITransaction[]; count: number }>;
  });
};

export const fetchSetTransactions = (setData: ITransactionCreate) => {
  const path = `${BACKEND_URL}/user/transactions`;

  const body = JSON.stringify({
    ...setData,
  });

  const data = {
    method: 'POST',
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json();
  });
};

export const fetchSetProperty = (id: number, setData: IProperty) => {
  const path = `${BACKEND_URL}/user/transactions/${id}/property`;

  const body = JSON.stringify({
    ...setData,
  });

  const data = {
    method: 'PATCH',
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json();
  });
};

export const fetchGetTransactionsById = (id: number) => {
  const path = `${BACKEND_URL}/user/transactions/${id}`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<ITransaction>;
  });
};

export const fetchSetMember = (id: number, setData: IMember) => {
  const path = `${BACKEND_URL}/user/transactions/${id}/members`;

  const body = JSON.stringify({
    ...setData,
  });

  const data = {
    method: 'PATCH',
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json();
  });
};

export const fetchGetEstimate = (id: number) => {
  const path = `${BACKEND_URL}/user/transactions/${id}/estimate`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<IEstimate>;
  });
};

export const fetchGetEmails = (id: number) => {
  const path = `${BACKEND_URL}/user/emails/partners?transaction_id=${id}`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<IEmailPartner[]>;
  });
};

export const fetchSetDocument = (transaction_id: number, task_id: number, setData: any) => {
  const path = `${BACKEND_URL}/user/pandadoc?transaction_id=${transaction_id}&task_id=${task_id}`;

  const body = JSON.stringify({
    ...setData,
  });

  const data = {
    method: 'PATCH',
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json();
  });
};
