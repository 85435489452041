<template>
  <component
    :is="disabled ? 'span' : 'router-link'"
    class="Logo logo"
    :to="to"
    exact
  >
    <img
      class="logo__desktop"
      :src="logoIcon"
      alt="logo"
      width="250"
      height="30"
    >
  </component>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { ROUTE_HOME } from '@/helpers/enums/routes';

import logoIcon from '@/assets/images/logo.svg';
import { useCore } from '@/store';

export default defineComponent({
  name: 'TheLogo',
  props: {
    disabled: Boolean,
  },
  setup() {
    const { person } = useCore();
    const to = computed(() => (
      person.value.loggedIn ? { name: ROUTE_HOME } : { name: ROUTE_HOME }));

    return {
      to,
      logoIcon,
    };
  },
});
</script>

<style lang="scss">
.logo {
  display: flex;
  align-items: center;
  padding-bottom: 0;
  border-bottom: $unset-value;
  text-decoration: none;

  &__desktop {
    width: 250px;
    height: auto;
  }

  &__text {
    font-size: 12px;
    font-weight: 400;
    color: $grayscale50;
    text-decoration: none;
    margin-top: 10px;
    margin-left: 4px;
  }
}

</style>
