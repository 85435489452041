<template>
  <div
    :class="{
      'is--loading': loading,
      'is--clickable': clickable,
    }"
    class="HeaderCard header-card"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderCard',
  props: {
    loading: Boolean,
    clickable: Boolean,
  },
});
</script>

<style lang="scss">
.header-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  color: $color-text-header;
  transition: all 0.2s;

  &:hover {
    cursor: pointer;
  }

  &.is--loading {
    pointer-events: none;
  }
}
</style>
