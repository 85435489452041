import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "base-button__inner" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "base-button__loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_svg_inline = _resolveDirective("svg-inline")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.href ? 'a' : _ctx.to ? 'router-link' : 'button'), _mergeProps(_ctx.$attrs, {
    to: _ctx.to,
    href: _ctx.href,
    class: [_ctx.classes, "BaseButton base-button is--button"],
    disabled: _ctx.to ? false : _ctx.disabled,
    "data-testid": "base-button",
    onClick: _ctx.onClick
  }), {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        (_ctx.navigationPrev)
          ? _withDirectives((_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "base-button__nav-icon",
              src: require(`@/assets/images/icons/prev.svg`),
              alt: "base-button__nav-icon-prev"
            }, null, 8, _hoisted_2)), [
              [_directive_svg_inline]
            ])
          : _createCommentVNode("", true),
        (_ctx.icon)
          ? _withDirectives((_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "base-button__icon",
              src: require(`@/assets/images/icons/${_ctx.iconPathName}.svg`),
              alt: "base-button__icon"
            }, null, 8, _hoisted_3)), [
              [_directive_svg_inline]
            ])
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.text), 1)
        ]),
        (_ctx.navigationNext)
          ? _withDirectives((_openBlock(), _createElementBlock("img", {
              key: 2,
              class: "base-button__nav-icon",
              src: require(`@/assets/images/icons/next.svg`),
              alt: "base-button__nav-icon-next"
            }, null, 8, _hoisted_4)), [
              [_directive_svg_inline]
            ])
          : _createCommentVNode("", true)
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["to", "href", "class", "disabled", "onClick"]))
}