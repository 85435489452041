<template>
  <div class="Footer footer">
    <div class="footer__top">
      <div class="footer__left">
        <Logo class="footer__logo" />
        <p
          class="footer__description is--body-text-1"
          v-text="'Sell your home like a pro.'"
        />
        <a
          class="footer__contact is--body-text-2"
          :href="`mailto:ask@yoursunday.com`"
          v-text="'ask@yoursunday.com'"
        />
      </div>
      <div class="footer__right">
        <ul class="footer__nav footer-nav">
          <li
            v-for="menuItem in MENU_FOOTER"
            :key="menuItem.text"
            class="footer-nav__item"
          >
            <router-link
              v-if="menuItem.name"
              :to="{ name: menuItem.name }"
              class="is--button"
              v-text="menuItem.text "
            />
            <a
              v-if="menuItem.href"
              :href="menuItem.href"
              target="_blank"
              class="is--button"
              v-text="menuItem.text "
            />
          </li>
        </ul>
        <!-- <SocialLinks
          :social-list="FOOTER_SOCIAL_LIST"
          class="footer__social"
        /> -->
      </div>
    </div>
    <div class="footer__bottom">
      <p
        class="footer__copyright is--small-text-1"
        v-text="`© Sunday Inc. ${ currentYear }`"
      />
      <div class="footer__terms is--small-text-1">
        <a
          class="footer__terms-item is--small-text-1"
          :href="`${env.FRONTEND_APP_URL}/privacy-policy`"
          target="_blank"
          v-text="'Privacy Policy'"
        />
        <a
          class="footer__terms-item is--small-text-1"
          :href="`${env.FRONTEND_APP_URL}/terms-of-use`"
          target="_blank"
          v-text="'Terms of Use'"
        />
        <a
          class="footer__terms-item is--small-text-1"
          :href="`${env.FRONTEND_APP_URL}/cookie-policy`"
          target="_blank"
          v-text="'Cookie Policy'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
} from 'vue';
import { MENU_FOOTER, FOOTER_SOCIAL_LIST } from '@/config/menu';
import Logo from '@/components/common/Logo.vue';
// import SocialLinks from '@/components/common/SocialLinks.vue';
import { ROUTE_HOME } from '@/helpers/enums/routes';
import env from '@/config/index';

export default defineComponent({
  name: 'TheFooter',
  components: {
    Logo,
    // SocialLinks,
  },
  setup() {
    const currentYear = new Date().getFullYear();

    return {
      currentYear,
      MENU_FOOTER,
      FOOTER_SOCIAL_LIST,
      ROUTE_HOME,
      env,
    };
  },
});
</script>

<style lang="scss">
.footer {
  padding: 48px 48px 32px 48px;
  border-radius: 30px;
  background: $pinkLighter;
  max-width: 1146px;
  margin: 0 auto;
  min-height: 300px;
  @include media-lte(tablet) {
    padding: 32px 24px;
  }

  &__top {
    border-bottom: 1px solid $grayscale30;
    padding-bottom: 31px;
    gap: 20px;
    @include media-gt(tablet) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__logo {
    margin-bottom: 16px;
  }

  &__description {
    color: $grayscale90;
    margin-bottom: 32px;
    @include media-lte(tablet) {
      margin-bottom: 24px;
    }
  }

  &__contact {
    display: block;
    margin-bottom: 16px;
    color: $accent;
    text-decoration: underline;
    @include media-lte(tablet) {
      margin-bottom: 29px;
    }
  }

  &__copyright {
    color: $grayscale60;
    margin-bottom: 0;
  }

  .footer-nav {
    @include media-gt(tablet) {
      display: flex;
      flex-direction: row;
      justify-content: end;
      flex-wrap: wrap;
      gap: 15px;
    }
    @include media-lte(tablet) {
      margin-bottom: 31px;
    }

    &__item {
      flex-shrink: 0;

      @include media-gt(tablet) {
        width: 30%;
        flex-shrink: 0;
      }
      @include media-lte(tablet) {
        margin-bottom: 21px;
      }
    }
  }

  &__social {
    @include media-gt(tablet) {
      justify-content: flex-end;
    }
  }

  &__terms-item {
    color:$accent;
    text-decoration: underline;
    min-width: 70px;

    & + & {
      margin-left: 45px;
      @include media-lte(tablet) {
        margin-left: 26px;
      }
    }
  }

  &__terms {
    min-height: 18px;
  }
}
</style>
