import { ref } from 'vue';
import {
  errorHandling422, errorHandlingRecovery, errorHandlingSettings, generalErrorHandling,
} from '@/helpers/authUtils';
import {
  IGetAuthFlow, IGetLogoutURL, IGetSettingsOk, IRecovery, ISession, ISetLoginOk, ISetSignUpOK,
} from '@/types/api/auth';
import {
  fetchGetSession, fetchSetLogin, fetchGetLogout, fetchGetLogoutURL,
  fetchSetSignUp, fetchSetRecovery, fetchSetPassword, fetchAuthFlow,
} from '@/store';

const isGetSessionLoading = ref(false);
const isGetFlowLoading = ref(false);
const isSetLoginLoading = ref(false);
const isGetLogoutLoading = ref(false);
const isGetLogoutURLLoading = ref(false);
const isSetSignupLoading = ref(false);
const isSetRecoveryLoading = ref(false);
const isSetPasswordLoading = ref(false);

const isGetFlowError = ref(false);
const isGetSessionError = ref(false);
const isSetLoginError = ref(false);
const isGetLogoutError = ref(false);
const isGetLogoutURLError = ref(false);
const isSetSignupError = ref(false);
const isSetRecoveryError = ref(false);
const isSetPasswordError = ref(false);

const getSessionData = ref<ISession>();
const getFlowData = ref<IGetAuthFlow>();
const setLoginData = ref<ISetLoginOk>();
const getLogoutURLData = ref<IGetLogoutURL>();
const setSignupData = ref<ISetSignUpOK>();
const setRecoveryData = ref<IRecovery>();
const setPasswordData = ref<IGetSettingsOk>();

const getLogoutResponse = ref<Response | void>();

export const useAuth = () => {
  const getSession = async () => {
    isGetSessionLoading.value = true;
    isGetSessionError.value = false;
    const response = await fetchGetSession().catch(() => {
      isGetSessionError.value = true;
    });
    if (response) getSessionData.value = response;
    isGetSessionLoading.value = false;
  };

  const fetchAuthHandler = async (url: string) => {
    isGetFlowLoading.value = true;
    const response = await fetchAuthFlow(url).catch((error: Response) => {
      isGetFlowError.value = true;
      void generalErrorHandling(error);
    });
    if (response) getFlowData.value = response;
    isGetFlowLoading.value = false;
  };

  const setLogin = async (
    flowId: string,
    password: string,
    email: string,
    csrf_token: string,
  ) => {
    isSetLoginLoading.value = true;
    isSetLoginError.value = false;
    const response = await fetchSetLogin(flowId, password, email, csrf_token).catch((error: Response) => {
      isSetLoginError.value = true;
      void errorHandling422(error);
    });
    if (response) setLoginData.value = response;
    isSetLoginLoading.value = false;
  };

  const getLogout = async (token: string) => {
    isGetLogoutLoading.value = true;
    isGetLogoutError.value = false;
    getLogoutResponse.value = await fetchGetLogout(token)
      .catch((error: Response) => {
        isGetLogoutError.value = true;
        void generalErrorHandling(error);
      });
    isGetLogoutLoading.value = false;
  };

  const getLogoutUrl = async () => {
    isGetLogoutURLLoading.value = true;
    isGetLogoutURLError.value = false;
    const response = await fetchGetLogoutURL().catch((error: Response) => {
      isGetLogoutURLError.value = true;
      void generalErrorHandling(error);
    });
    if (response) getLogoutURLData.value = response;
    isGetLogoutURLLoading.value = false;
  };

  const setSignup = async (
    flowId: string,
    password: string,
    firstName: string,
    lastName: string,
    email: string,
    role: string,
    phone: string,
    csrf_token: string,
  ) => {
    isSetSignupLoading.value = true;
    isSetSignupError.value = false;
    const response = await fetchSetSignUp(flowId, password, firstName, lastName, email, role, phone, csrf_token)
      .catch((error: Response) => {
        isSetSignupError.value = true;
        void errorHandling422(error);
      });
    if (response) setSignupData.value = response;
    isSetSignupLoading.value = false;
  };

  const resetSetSignupError = () => {
    isSetSignupError.value = false;
  };

  const setRecovery = async (
    flowId: string,
    email: string,
    csrf_token: string,
  ) => {
    isSetRecoveryLoading.value = true;
    isSetRecoveryError.value = false;
    const response = await fetchSetRecovery(flowId, email, csrf_token)
      .catch((error: Response) => {
        isSetRecoveryError.value = true;
        void errorHandlingRecovery(error);
      });
    if (response) setRecoveryData.value = response;
    isSetRecoveryLoading.value = false;
  };

  const setPassword = async (
    flowId: string,
    password: string,
    csrf_token: string,
  ) => {
    isSetPasswordLoading.value = true;
    isSetPasswordError.value = false;
    const response = await fetchSetPassword(flowId, password, csrf_token)
      .catch((error: Response) => {
        isSetPasswordError.value = true;
        void errorHandlingSettings(error);
      });
    if (response) setPasswordData.value = response;
    isSetPasswordLoading.value = false;
  };

  const resetAuthAll = () => {
    setLoginData.value = undefined;
    setSignupData.value = undefined;
    setRecoveryData.value = undefined;
    setPasswordData.value = undefined;
  };

  return {
    isGetSessionLoading,
    isSetLoginLoading,
    isGetLogoutLoading,
    isGetLogoutURLLoading,
    isSetSignupLoading,
    isSetRecoveryLoading,
    isSetPasswordLoading,

    isGetSessionError,
    isSetLoginError,
    isGetLogoutError,
    isGetLogoutURLError,
    isSetSignupError,
    isSetPasswordError,

    getSessionData,
    getFlowData,
    setLoginData,
    getLogoutURLData,
    setSignupData,
    setRecoveryData,
    setPasswordData,

    getLogoutResponse,

    getSession,
    fetchAuthHandler,
    setLogin,
    getLogout,
    getLogoutUrl,
    setSignup,
    setRecovery,
    setPassword,
    resetAuthAll,
    resetSetSignupError,
  };
};

