import env from '@/config';

const { HUBSPOT_FORM, HUBSPOT_PORTAL_ID } = env;

export const fetchHubspotForm = (hubspotFormId: string, data: object) => {
  const path = `${HUBSPOT_FORM}/${HUBSPOT_PORTAL_ID}/${hubspotFormId}`;
  const body = JSON.stringify(data);

  const req = {
    body,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(path, req).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<unknown>;
  });
};
