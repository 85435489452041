export function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

export function gtagClick(label) {
  gtag('event', 'click', {
    event_category: 'Button Click',
    event_label: label,
  });
}
export function gtagFlowStart(label) {
  gtag('event', 'start_flow', {
    event_category: 'Flow Start',
    event_label: label,
  });
}

export function gtagFlowComplete(label) {
  gtag('event', 'complete_flow', {
    event_category: 'Flow Complete',
    event_label: label,
  });
}
