import parse from '@/helpers/parseMarkdown';
import { IPostContent } from '@/types/api/blog';

// This condition actually should detect if it's an Node environment
if (typeof require.context === 'undefined') {
  // eslint-disable-next-line
  const fs = require('fs');
  // eslint-disable-next-line
  const path = require('path');

  require.context = (base = '.', scanSubDirectories = false, regularExpression = /\.js$/) => {
    const files = {};

    // eslint-disable-next-line
    function readDirectory(directory: any) {
      // eslint-disable-next-line
      fs.readdirSync(directory).forEach((file: any) => {
        // eslint-disable-next-line
        const fullPath = path.resolve(directory, file) as string;
        // eslint-disable-next-line
        if (fs.statSync(fullPath).isDirectory()) {
          if (scanSubDirectories) readDirectory(fullPath);

          return;
        }
        // eslint-disable-next-line
        if (!regularExpression.test(fullPath)) return;

        // files[fullPath] = true;
      });
    }
    // eslint-disable-next-line
    readDirectory(path.resolve(__dirname, base));

    // eslint-disable-next-line
    function Module(file: any) {
      // eslint-disable-next-line
      return require(file);
    }

    Module.keys = () => Object.keys(files);

    return Module;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requireModule: any = require.context('../../../public/blog/', false, /\.md$/);
const markdownItems: IPostContent[] = [];

/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
requireModule.keys().forEach((fileName: string) => {
  try {
    markdownItems.push(parse(requireModule(fileName).default as string));
  } catch (err) {
    // eslint-disable-next-line
    console.error(`${err} in ${fileName}`);
  }
});

markdownItems.sort((a, b) => {
  const dateA = new Date(a.date).getTime();
  const dateB = new Date(b.date).getTime();
  return dateA > dateB ? -1 : 1;
});

// import env from '@/global';

// const { KRATOS_URL } = env;

// GET BLOG POSTS
export const fetchGetAllBlogPosts = () => Promise.resolve(markdownItems);
export const fetchGetOneBlogPost = (slug: string) => {
  const getOne = markdownItems.find((item) => item.slug === slug);
  return Promise.resolve(getOne);
};
