import env from '@/config';
import { ICheckout } from '@/types/api/payments';

const { PAYMENTS_URL } = env;

export const fetchCheckout = (plan: string, period = 'month') => {
  const path = `${PAYMENTS_URL}/subscription/checkout?plan=${plan}&period=${period}`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<ICheckout>;
  });
};
