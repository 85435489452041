import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "DefaultAvatar default-avatar" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.imageUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "default-avatar__image",
          src: _ctx.imageUrl,
          alt: "avatar"
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "default-avatar__icon is--body-text-1",
          textContent: _toDisplayString(_ctx.userLetter)
        }, null, 8, _hoisted_3))
  ]))
}