import {
  INotification,
} from '@/types/api/notifications';

import env from '@/config';

const { NOTIFICATION_URL } = env;

export const fetchGetNotificationsAll = () => {
  const path = `${NOTIFICATION_URL}/notification`;

  const data = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as RequestCredentials,
  };

  return fetch(path, data).then((response) => {
    if (!response.ok) return Promise.reject(response);
    return response.json() as Promise<INotification[]>;
  });
};
