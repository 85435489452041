import { StorageService } from './StorageService';
import { IUser } from '@/types/api/plusApi';
import { ISignUpFormData } from '@/types/api/auth';

export const storage = {
  loggedIn: new StorageService<boolean>('loggedIn'),
  user: new StorageService<IUser | null>('user'),
  signUpFormData: new StorageService<ISignUpFormData>('signUpFormData'),
  onboardingFinished: new StorageService<boolean>('onboardingFinished'),
  pricePlan: new StorageService<string>('pricePlan'),
  selectedTransactionId: new StorageService<number>('selectedTransactionId'),
  sellingTips: new StorageService<boolean>('sellingTips'),
  salesInsights: new StorageService<boolean>('salesInsights'),
};
