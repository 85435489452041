import { ref } from 'vue';

import { fetchGetAllBlogPosts, fetchGetOneBlogPost } from '@/store';
import { IPostContent } from '@/types/api/blog';

const isGetAllBlogPostsLoading = ref<boolean>(false);
const isGetOneBlogPostLoading = ref<boolean>(false);

const isGetAllBlogPostsError = ref<boolean>(false);
const isGetOneBlogPostError = ref<boolean>(false);

const getAllBlogPostsData = ref<IPostContent[]>();
const getOneBlogPostData = ref<IPostContent>();

export const useBlog = () => {
  const getAllBlogPosts = async () => {
    isGetAllBlogPostsLoading.value = true;
    const response = await fetchGetAllBlogPosts().catch(() => {
      isGetAllBlogPostsError.value = true;
    });
    if (response) getAllBlogPostsData.value = response;
    isGetAllBlogPostsLoading.value = false;
  };

  const getOneBlogPost = async (slug: string) => {
    isGetOneBlogPostLoading.value = true;
    const response = await fetchGetOneBlogPost(slug).catch(() => {
      isGetOneBlogPostError.value = true;
    });
    if (response) getOneBlogPostData.value = response;
    isGetOneBlogPostLoading.value = false;
  };

  return {
    isGetAllBlogPostsLoading,
    isGetOneBlogPostLoading,

    isGetAllBlogPostsError,
    isGetOneBlogPostError,

    getAllBlogPostsData,
    getOneBlogPostData,

    getAllBlogPosts,
    getOneBlogPost,
  };
};
