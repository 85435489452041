import {
  ROUTE_PRICING,
  ROUTE_HOW_IT_WORKS,
  ROUTE_HOME,
} from '@/helpers/enums/routes';
import env from '@/config/index';
import { useCore } from '@/store';
import linkedinIcon from '@/assets/images/social/linkedin.svg';
import facebookIcon from '@/assets/images/social/facebook.svg';
import instagramIcon from '@/assets/images/social/instagram.svg';
import twitterIcon from '@/assets/images/social/twitter.svg';
import { computed } from 'vue';

const { person } = useCore();

export type MenuItem = {
  name?: string;
  href?: string;
  text: string;
}
/* TODO fix routes */
export const MENU_HEADER = computed(() => ([
  {
    href: `${env.FRONTEND_APP_URL}/dashboard`,
    text: 'Go to Dashboard',
  },
  {
    name: ROUTE_HOW_IT_WORKS,
    text: 'How it Works',
  },
  {
    href: `${env.FRONTEND_APP_URL}/pricing`,
    text: 'Pricing',
  },
] as MenuItem[]));

export const MENU_FOOTER: MenuItem[] = [
  {
    name: ROUTE_HOME,
    text: 'Home',
  },
  {
    href: `${env.FRONTEND_APP_URL}/resource-center`,
    text: 'Articles & Advice',
  },
  {
    href: `${env.FRONTEND_APP_URL}/dashboard`,
    text: 'Dashboard',
  },
  {
    name: ROUTE_HOW_IT_WORKS,
    text: 'How it Works',
  },
  {
    href: `${env.FRONTEND_APP_URL}/faq`,
    text: 'FAQ',
  },
  {
    href: `${env.FRONTEND_APP_URL}/pricing`,
    text: 'Pricing',
  },
  {
    href: `${env.FRONTEND_APP_URL}/contact-us`,
    text: 'Contact us',
  },
];

// TODO fix socials

interface ISocial {
  icon: string;
  href: string;
}
export const LINKEDIN: ISocial = {
  // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment
  icon: linkedinIcon,
  href: 'https://www.linkedin.com/company/yoursunday',
};

export const FACEBOOK: ISocial = {
  // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment
  icon: facebookIcon,
  href: 'https://www.facebook.com/yoursunday',
};

export const INSTAGRAM: ISocial = {
  // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment
  icon: instagramIcon,
  href: 'https://www.instagram.com/yoursunday',
};

export const TWITTER: ISocial = {
  // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment
  icon: twitterIcon,
  href: 'https://www.twitter.com/yoursunday',
};


export const FOOTER_SOCIAL_LIST = [
  FACEBOOK,
  TWITTER,
  LINKEDIN,
  INSTAGRAM,
];
